
window.theme = window.theme || {}, window.slate = window.slate || {}, console && console.log && console.log("Motion theme (" + theme.settings.themeVersion + ") by ARCHÎžTYPE | Learn more at https://archetypethemes.co"), window.lazySizesConfig = window.lazySizesConfig || {}, lazySizesConfig.expFactor = 4,
    function(e) {
        e = jQuery = e;
        slate.utils = {
            defaultTo: function(e, t) {
                return null == e || e != e ? t : e
            }
        }, slate.a11y = {
            trapFocus: function(t) {
                var i = t.namespace ? "focusin." + t.namespace : "focusin";
                t.$elementToFocus || (t.$elementToFocus = t.$container), t.$container.attr("tabindex", "-1"), t.$elementToFocus.focus(), e(document).off("focusin"), e(document).on(i, function(e) {
                    t.$container[0] === e.target || t.$container.has(e.target).length || t.$container.focus()
                })
            },
            removeTrapFocus: function(t) {
                var i = t.namespace ? "focusin." + t.namespace : "focusin";
                t.$container && t.$container.length && t.$container.removeAttr("tabindex"), e(document).off(i)
            },
            lockMobileScrolling: function(t, i) {
                if (i) var n = i;
                else n = e(document.documentElement).add("body");
                n.on("touchmove" + t, function() {
                    return !1
                })
            },
            unlockMobileScrolling: function(t, i) {
                if (i) var n = i;
                else n = e(document.documentElement).add("body");
                n.off(t)
            }
        }, theme.Sections = function() {
            this.constructors = {}, this.instances = [], e(document).on("shopify:section:load", this._onSectionLoad.bind(this)).on("shopify:section:unload", this._onSectionUnload.bind(this)).on("shopify:section:select", this._onSelect.bind(this)).on("shopify:section:deselect", this._onDeselect.bind(this)).on("shopify:block:select", this._onBlockSelect.bind(this)).on("shopify:block:deselect", this._onBlockDeselect.bind(this))
        }, theme.Sections.prototype = e.extend({}, theme.Sections.prototype, {
            createInstance: function(t, i, n) {
                var a = e(t),
                    o = a.attr("data-section-id"),
                    s = a.attr("data-section-type");
                if (void 0 !== (i = i || this.constructors[s])) {
                    if (n)
                        if (this._findInstance(o)) return;
                    var r = e.extend(new i(t), {
                        id: o,
                        type: s,
                        container: t
                    });
                    this.instances.push(r)
                }
            },
            _onSectionLoad: function(t, i, n) {
                AOS && AOS.refreshHard();
                var a = i || e("[data-section-id]", t.target)[0];
                if (a) {
                    this.createInstance(a);
                    var o = i ? n : this._findInstance(t.detail.sectionId);
                    i || this._loadSubSections(), o && "function" == typeof o.onLoad && o.onLoad(t)
                }
            },
            _loadSubSections: function() {
                AOS && AOS.refreshHard(), e("[data-subsection]").each(function(t, i) {
                    this._onSectionLoad(null, i, e(i).data("section-id"))
                }.bind(this))
            },
            _onSectionUnload: function(e) {
                var t = this._removeInstance(e.detail.sectionId);
                t && "function" == typeof t.onUnload && t.onUnload(e)
            },
            _onSelect: function(e) {
                var t = this._findInstance(e.detail.sectionId);
                t && "function" == typeof t.onSelect && t.onSelect(e)
            },
            _onDeselect: function(e) {
                var t = this._findInstance(e.detail.sectionId);
                t && "function" == typeof t.onDeselect && t.onDeselect(e)
            },
            _onBlockSelect: function(e) {
                var t = this._findInstance(e.detail.sectionId);
                t && "function" == typeof t.onBlockSelect && t.onBlockSelect(e)
            },
            _onBlockDeselect: function(e) {
                var t = this._findInstance(e.detail.sectionId);
                t && "function" == typeof t.onBlockDeselect && t.onBlockDeselect(e)
            },
            _findInstance: function(e) {
                for (var t = 0; t < this.instances.length; t++)
                    if (this.instances[t].id === e) return this.instances[t]
            },
            _removeInstance: function(e) {
                for (var t, i = this.instances.length; i--;)
                    if (this.instances[i].id === e) {
                        t = this.instances[i], this.instances.splice(i, 1);
                        break
                    }
                return t
            },
            register: function(t, i, n) {
                this.constructors[t] = i;
                var a = e("[data-section-type=" + t + "]");
                n && (a = e("[data-section-type=" + t + "]", n)), a.each(function(e, t) {
                    this.createInstance(t, i, n)
                }.bind(this))
            }
        }), theme.Currency = function() {
            var e = "$";
            return {
                formatMoney: function(t, i) {
                    i || (i = theme.settings.moneyFormat), "string" == typeof t && (t = t.replace(".", ""));
                    var n = "",
                        a = /\{\{\s*(\w+)\s*\}\}/,
                        o = i || e;

                    function s(e, t, i, n) {
                        if (t = slate.utils.defaultTo(t, 2), i = slate.utils.defaultTo(i, ","), n = slate.utils.defaultTo(n, "."), isNaN(e) || null == e) return 0;
                        var a = (e = (e / 100).toFixed(t)).split(".");
                        return a[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + i) + (a[1] ? n + a[1] : "")
                    }
                    switch (o.match(a)[1]) {
                        case "amount":
                            n = s(t, 2);
                            break;
                        case "amount_no_decimals":
                            n = s(t, 0);
                            break;
                        case "amount_with_comma_separator":
                            n = s(t, 2, ".", ",");
                            break;
                        case "amount_no_decimals_with_comma_separator":
                            n = s(t, 0, ".", ",");
                            break;
                        case "amount_no_decimals_with_space_separator":
                            n = s(t, 0, " ")
                    }
                    return o.replace(a, n)
                },
                getBaseUnit: function(e) {
                    if (e && e.unit_price_measurement && e.unit_price_measurement.reference_value) return 1 === e.unit_price_measurement.reference_value ? e.unit_price_measurement.reference_unit : e.unit_price_measurement.reference_value + e.unit_price_measurement.reference_unit
                }
            }
        }(), theme.Images = function() {
            return {
                imageSize: function(e) {
                    if (!e) return "620x";
                    var t = e.match(/.+_((?:pico|icon|thumb|small|compact|medium|large|grande)|\d{1,4}x\d{0,4}|x\d{1,4})[_\.@]/);
                    return null !== t ? t[1] : null
                },
                getSizedImageUrl: function(e, t) {
                    if (!e) return e;
                    if (null == t) return e;
                    if ("master" === t) return this.removeProtocol(e);
                    var i = e.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i);
                    if (null != i) {
                        var n = e.split(i[0]),
                            a = i[0];
                        return this.removeProtocol(n[0] + "_" + t + a)
                    }
                    return null
                },
                removeProtocol: function(e) {
                    return e.replace(/http(s)?:/, "")
                }
            }
        }(), slate.Variants = function() {
            function t(t) {
                this.$container = t.$container, this.variants = t.variants, this.singleOptionSelector = t.singleOptionSelector, this.originalSelectorId = t.originalSelectorId, this.enableHistoryState = t.enableHistoryState, this.currentVariant = this._getVariantFromOptions(), e(this.singleOptionSelector, this.$container).on("change", this._onSelectChange.bind(this))
            }
            return t.prototype = e.extend({}, t.prototype, {
                _getCurrentOptions: function() {
                    var t = e.map(e(this.singleOptionSelector, this.$container), function(t) {
                        var i = e(t),
                            n = i.attr("type"),
                            a = {};
                        return "radio" === n || "checkbox" === n ? !!i[0].checked && (a.value = i.val(), a.index = i.data("index"), a) : (a.value = i.val(), a.index = i.data("index"), a)
                    });
                    return t = this._compact(t)
                },
                _getVariantFromOptions: function() {
                    var e = this._getCurrentOptions(),
                        t = this.variants,
                        i = !1;
                    return t.forEach(function(t) {
                        var n = !0;
                        t.options;
                        e.forEach(function(e) {
                            n && (n = t[e.index] === e.value)
                        }), n && (i = t)
                    }), i || null
                },
                _onSelectChange: function() {
                    var e = this._getVariantFromOptions();
                    this.$container.trigger({
                        type: "variantChange",
                        variant: e
                    }), e && (this._updateMasterSelect(e), this._updateImages(e), this._updatePrice(e), this._updateUnitPrice(e), this._updateSKU(e), this.currentVariant = e, this.enableHistoryState && this._updateHistoryState(e))
                },
                _updateImages: function(e) {
                    var t = e.featured_image || {},
                        i = this.currentVariant.featured_image || {};
                    e.featured_image && t.src !== i.src && this.$container.trigger({
                        type: "variantImageChange",
                        variant: e
                    })
                },
                _updatePrice: function(e) {
                    e.price === this.currentVariant.price && e.compare_at_price === this.currentVariant.compare_at_price || this.$container.trigger({
                        type: "variantPriceChange",
                        variant: e
                    })
                },
                _updateUnitPrice: function(e) {
                    e.unit_price !== this.currentVariant.unit_price && this.$container.trigger({
                        type: "variantUnitPriceChange",
                        variant: e
                    })
                },
                _updateSKU: function(e) {
                    e.sku !== this.currentVariant.sku && this.$container.trigger({
                        type: "variantSKUChange",
                        variant: e
                    })
                },
                _updateHistoryState: function(e) {
                    if (history.replaceState && e) {
                        var t = window.location.protocol + "//" + window.location.host + window.location.pathname + "?variant=" + e.id;
                        window.history.replaceState({
                            path: t
                        }, "", t)
                    }
                },
                _updateMasterSelect: function(t) {
                    e(this.originalSelectorId, this.$container).val(t.id)
                },
                _compact: function(e) {
                    for (var t = -1, i = null == e ? 0 : e.length, n = 0, a = []; ++t < i;) {
                        var o = e[t];
                        o && (a[n++] = o)
                    }
                    return a
                }
            }), t
        }(), slate.rte = {
            init: function() {
                slate.rte.wrapTable(), slate.rte.wrapVideo(), slate.rte.imageLinks()
            },
            wrapTable: function() {
                e(".rte table").wrap('<div class="table-wrapper"></div>')
            },
            wrapVideo: function() {
                var t = e('.rte iframe[src*="youtube.com/embed"], .rte iframe[src*="player.vimeo"]'),
                    i = t.add("iframe#admin_bar_iframe");
                t.each(function() {
                    e(this).parents(".video-wrapper").length || e(this).wrap('<div class="video-wrapper"></div>')
                }), i.each(function() {
                    this.src = this.src
                })
            },
            imageLinks: function() {
                e(".rte a img").parent().addClass("rte__image")
            }
        }, theme.Modals = function() {
            function t(t, i, n) {
                var a = {
                    close: ".js-modal-close",
                    open: ".js-modal-open-" + i,
                    openClass: "modal--is-active",
                    closingClass: "modal--is-closing",
                    bodyOpenClass: "modal-open",
                    bodyOpenSolidClass: "modal-open--solid",
                    bodyClosingClass: "modal-closing",
                    closeOffContentClick: !0
                };
                if (this.id = t, this.$modal = e("#" + t), !this.$modal.length) return !1;
                this.nodes = {
                    $parent: e("html").add("body"),
                    $modalContent: this.$modal.find(".modal__inner")
                }, this.config = e.extend(a, n), this.modalIsOpen = !1, this.$focusOnOpen = this.config.focusOnOpen ? e(this.config.focusOnOpen) : this.$modal, this.isSolid = this.config.solid, this.init()
            }
            return t.prototype.init = function() {
                e(this.config.open).attr("aria-expanded", "false"), e(this.config.open).on("click", this.open.bind(this)), this.$modal.find(this.config.close).on("click", this.close.bind(this)), e("body").on("drawerOpen", function() {
                    this.close()
                }.bind(this))
            }, t.prototype.open = function(t) {
                var i = !1;
                this.modalIsOpen || (t ? t.preventDefault() : i = !0, t && t.stopPropagation && (t.stopPropagation(), this.$activeSource = e(t.currentTarget)), this.modalIsOpen && !i && this.close(), this.$modal.prepareTransition().addClass(this.config.openClass), this.nodes.$parent.addClass(this.config.bodyOpenClass), this.isSolid && this.nodes.$parent.addClass(this.config.bodyOpenSolidClass), this.modalIsOpen = !0, slate.a11y.trapFocus({
                    $container: this.$modal,
                    $elementToFocus: this.$focusOnOpen,
                    namespace: "modal_focus"
                }), this.$activeSource && this.$activeSource.attr("aria-expanded") && this.$activeSource.attr("aria-expanded", "true"), e("body").trigger("modalOpen").trigger("modalOpen." + this.id), this.bindEvents())
            }, t.prototype.close = function() {
                if (this.modalIsOpen) {
                    e(document.activeElement).trigger("blur"), this.$modal.prepareTransition().removeClass(this.config.openClass).addClass(this.config.closingClass), this.nodes.$parent.removeClass(this.config.bodyOpenClass), this.nodes.$parent.addClass(this.config.bodyClosingClass);
                    var t = this;
                    window.setTimeout(function() {
                        t.nodes.$parent.removeClass(t.config.bodyClosingClass), t.$modal.removeClass(t.config.closingClass)
                    }, 500), this.isSolid && this.nodes.$parent.removeClass(this.config.bodyOpenSolidClass), this.modalIsOpen = !1, slate.a11y.removeTrapFocus({
                        $container: this.$modal,
                        namespace: "modal_focus"
                    }), this.$activeSource && this.$activeSource.attr("aria-expanded") && this.$activeSource.attr("aria-expanded", "false").focus(), e("body").trigger("modalClose." + this.id), this.unbindEvents()
                }
            }, t.prototype.bindEvents = function() {
                this.nodes.$parent.on("keyup.modal", function(e) {
                    27 === e.keyCode && this.close()
                }.bind(this)), this.config.closeOffContentClick && (this.$modal.on("click.modal", this.close.bind(this)), this.nodes.$modalContent.on("click.modal", function(e) {
                    e.stopImmediatePropagation()
                }))
            }, t.prototype.unbindEvents = function() {
                this.nodes.$parent.off(".modal"), this.config.closeOffContentClick && (this.$modal.off(".modal"), this.nodes.$modalContent.off(".modal"))
            }, t
        }(), theme.Drawers = function() {
            function t(t, i) {
                if (this.config = {
                        id: t,
                        close: ".js-drawer-close",
                        open: ".js-drawer-open-" + i,
                        openClass: "js-drawer-open",
                        closingClass: "js-drawer-closing",
                        activeDrawer: "drawer--is-open",
                        namespace: ".drawer-" + i
                    }, this.$nodes = {
                        parent: e(document.documentElement).add("body"),
                        page: e("#MainContent")
                    }, this.$drawer = e("#" + t), !this.$drawer.length) return !1;
                this.isOpen = !1, this.init()
            }
            return t.prototype = e.extend({}, t.prototype, {
                init: function() {
                    var t = e(this.config.open);
                    t.attr("aria-expanded", "false"), t.on("click", this.open.bind(this)), this.$drawer.find(this.config.close).on("click", this.close.bind(this)), e("body").on("modalOpen", function() {
                        this.close()
                    }.bind(this))
                },
                open: function(t) {
                    t && t.preventDefault(), this.isOpen || (t && t.stopPropagation && (t.stopPropagation(), this.$activeSource = e(t.currentTarget)), this.$drawer.prepareTransition().addClass(this.config.activeDrawer), this.$nodes.parent.addClass(this.config.openClass), this.isOpen = !0, slate.a11y.trapFocus({
                        $container: this.$drawer,
                        namespace: "drawer_focus"
                    }), e("body").trigger("drawerOpen").trigger("drawerOpen." + this.config.id), this.$activeSource && this.$activeSource.attr("aria-expanded") && this.$activeSource.attr("aria-expanded", "true"), this.bindEvents())
                },
                close: function() {
                    if (this.isOpen) {
                        e(document.activeElement).trigger("blur"), this.$drawer.prepareTransition().removeClass(this.config.activeDrawer), this.$nodes.parent.removeClass(this.config.openClass), this.$nodes.parent.addClass(this.config.closingClass);
                        var t = this;
                        window.setTimeout(function() {
                            t.$nodes.parent.removeClass(t.config.closingClass)
                        }, 500), this.isOpen = !1, slate.a11y.removeTrapFocus({
                            $container: this.$drawer,
                            namespace: "drawer_focus"
                        }), this.$activeSource && this.$activeSource.attr("aria-expanded") && this.$activeSource.attr("aria-expanded", "false"), this.unbindEvents()
                    }
                },
                bindEvents: function() {
                    slate.a11y.lockMobileScrolling(this.config.namespace, this.$nodes.page), this.$nodes.page.on("click" + this.config.namespace, function() {
                        return this.close(), !1
                    }.bind(this)), this.$nodes.parent.on("keyup" + this.config.namespace, function(e) {
                        27 === e.keyCode && this.close()
                    }.bind(this))
                },
                unbindEvents: function() {
                    slate.a11y.unlockMobileScrolling(this.config.namespace, this.$nodes.page), this.$nodes.parent.off(this.config.namespace), this.$nodes.page.off(this.config.namespace)
                }
            }), t
        }(), theme.cart = {
            getCart: function() {
              
                return e.getJSON("/cart.js")
            },
            changeItem: function(e, t) {
                return this._updateCart({
                    type: "POST",
                    url: "/cart/change.js",
                    data: "quantity=" + t + "&id=" + e,
                    dataType: "json"
                })
            },
            addItemFromForm: function(e) {
              
                return this._updateCart({
                    type: "POST",
                    url: "/cart/add.js",
                    data: e,
                    dataType: "json"
                })
            },
            _updateCart: function(t) {
                return e.ajax(t).then(function(e) {
                    return e
                }.bind(this))
            },
            updateNote: function(t) {
                var i = {
                    type: "POST",
                    url: "/cart/update.js",
                    data: "note=" + theme.cart.attributeToString(t),
                    dataType: "json",
                    success: function(e) {},
                    error: function(e, t) {}
                };
                e.ajax(i)
            },
            updateCurrency: function(t) {
                var i = {
                    type: "POST",
                    url: "/cart/update.js",
                    data: "currency=" + t,
                    dataType: "json",
                    success: function(e) {
                        location.reload()
                    },
                    error: function(e, t) {}
                };
                e.ajax(i)
            },
            attributeToString: function(t) {
                return "string" != typeof t && "undefined" === (t += "") && (t = ""), e.trim(t)
            }
        }, e(function() {
            e("body").on("click", ".cart__checkout", function() {
                e(this).addClass("btn--loading")
            }), e("body").on("change", 'textarea[name="note"]', function() {
                var t = e(this).val();
                theme.cart.updateNote(t)
            }), e("body").on("click", ".cart__checkout--ajax", function(t) {
                if (!e("#CartAgree").is(":checked")) return alert(theme.strings.cartTermsConfirmation), e(this).removeClass("btn--loading"), !1
            }), e("body").on("click", ".cart__checkout--page", function(t) {
                if (!e("#CartPageAgree").is(":checked")) return alert(theme.strings.cartTermsConfirmation), e(this).removeClass("btn--loading"), !1
            })
        }), theme.QtySelector = function() {
            var t = {
                input: ".js-qty__num",
                plus: ".js-qty__adjust--plus",
                minus: ".js-qty__adjust--minus"
            };

            function i(i, n) {
                this.$wrapper = i, this.$input = i.find(t.input), this.$plus = i.find(t.plus), this.$minus = i.find(t.minus), this.minValue = this.$input.attr("min") || 1;
                var a = {
                    namespace: null,
                    key: this.$input.data("id")
                };
                this.options = e.extend(a, n), this.initEventListeners()
            }
            return i.prototype = e.extend({}, i.prototype, {
                initEventListeners: function() {
                    this.$plus.on("click", function() {
                        var e = this.validateQty(this.$input.val());
                        this.addQty(e)
                    }.bind(this)), this.$minus.on("click", function() {
                        var e = this.validateQty(this.$input.val());
                        this.subtractQty(e)
                    }.bind(this)), this.$input.on("change", function() {
                        var e = this.validateQty(this.$input.val());
                        this.changeQty(e)
                    }.bind(this))
                },
                addQty: function(e) {
                    var t = e + 1;
                    this.changeQty(t)
                },
                subtractQty: function(e) {
                    var t = e - 1;
                    t <= this.minValue && (t = this.minValue), this.changeQty(t)
                },
                changeQty: function(t) {
                    this.$input.val(t), e("body").trigger("qty" + this.options.namespace, [this.options.key, t])
                },
                validateQty: function(e) {
                    return (parseFloat(e) != parseInt(e) || isNaN(e)) && (e = 1), parseInt(e)
                }
            }), i
        }(), theme.CartDrawer = function() {
            var t = ".ajaxcart",
                i = {
                    drawer: "#CartDrawer",
                    container: "#CartContainer",
                    template: "#CartTemplate",
                    fixedFooter: ".drawer__footer--fixed",
                    fixedInnerContent: ".drawer__inner--has-fixed-footer",
                    cartBubble: ".cart-link__bubble"
                };

            function n() {
                this.status = {
                    loaded: !1,
                    loading: !1
                }, this.drawer = new theme.Drawers("CartDrawer", "cart");
                var t = e(i.template).html();
                this.template = Handlebars.compile(t), theme.cart.getCart().then(this.buildCart.bind(this)), this.initEventListeners()
            }
            return n.prototype = e.extend({}, n.prototype, {
                initEventListeners: function() {
                    e("body").on("updateCart" + t, this.initQtySelectors.bind(this)), e("body").on("updateCart" + t, this.sizeFooter.bind(this)), e("body").on("updateCart" + t, this.updateCartNotification.bind(this)), e("body").on("drawerOpen.CartDrawer", this.sizeFooter.bind(this)), e(window).on("resize" + t, e.debounce(150, this.sizeFooter.bind(this))), e("body").on("added.ajaxProduct", function() {
                        theme.cart.getCart().then(function(e) {
                            this.buildCart(e, !0)
                        }.bind(this))
                    }.bind(this))
                },
                buildCart: function(n, a) {
                    if (this.loading(!0), this.emptyCart(), 0 === n.item_count) e(i.container).append('<p class="appear-animation appear-delay-3">' + theme.strings.cartEmpty + "</p>");
                    else {
                        var o, s = [],
                            r = {},
                            c = 1;
                        e.each(n.items, function(t, i) {
                            var n;
                            n = null !== i.image ? i.image.replace(/(\.[^.]*)$/, "_180x$1") : "//cdn.shopify.com/s/assets/admin/no-image-medium-cc9732cb976dd349a0df1d39816fbcc7.gif", null !== i.properties && e.each(i.properties, function(e, t) {
                                "_" !== e.charAt(0) && t || delete i.properties[e]
                            });
                            var a = 0;
                            if (0 !== i.line_level_discount_allocations.length)
                                for (var o in i.line_level_discount_allocations) a = i.line_level_discount_allocations[o].amount, i.line_level_discount_allocations[o].formattedAmount = theme.Currency.formatMoney(a, theme.settings.moneyFormat);
                            c += 2, r = {
                                key: i.key,
                                url: i.url,
                                img: n,
                                animationRow: c,
                                name: i.product_title,
                                variation: i.variant_title,
                                properties: i.properties,
                                itemQty: i.quantity,
                                price: theme.Currency.formatMoney(i.price, theme.settings.moneyFormat),
                                unitPrice: theme.Currency.formatMoney(i.unit_price, theme.settings.moneyFormat),
                                unitBase: theme.Currency.getBaseUnit(i),
                                discountedPrice: theme.Currency.formatMoney(i.price - i.total_discount / i.quantity, theme.settings.moneyFormat),
                                discounts: i.line_level_discount_allocations,
                                discountsApplied: 0 !== i.line_level_discount_allocations.length,
                                vendor: i.vendor
                            }, s.push(r)
                        }), c += 2;
                        var d = 0;
                        if (0 !== n.cart_level_discount_applications.length)
                            for (var l in n.cart_level_discount_applications) d = n.cart_level_discount_applications[l].total_allocated_amount, n.cart_level_discount_applications[l].formattedAmount = theme.Currency.formatMoney(d, theme.settings.moneyFormat);
                        o = {
                            items: s,
                            note: n.note,
                            lastAnimationRow: c,
                            cartDiscounts: n.cart_level_discount_applications,
                            cartDiscountsApplied: 0 !== n.cart_level_discount_applications.length,
                            totalPrice: theme.Currency.formatMoney(n.total_price, theme.settings.moneyFormat)
                        }, e(i.container).append(this.template(o))
                    }
                    this.status.loaded = !0, this.loading(!1), theme.settings.currenciesEnabled && theme.currencySwitcher.ajaxrefresh(), e("body").trigger("updateCart" + t, n), window.Shopify && Shopify.StorefrontExpressButtons && (Shopify.StorefrontExpressButtons.initialize(), setTimeout(function() {
                        this.sizeFooter()
                    }.bind(this), 800)), !0 === a && this.drawer.open()
                },
                initQtySelectors: function() {
                    e(i.container).find(".js-qty__wrapper").each(function(t, i) {
                        new theme.QtySelector(e(i), {
                            namespace: ".cart-drawer"
                        })
                    }.bind(this)), e("body").on("qty.cart-drawer", this.updateItem.bind(this))
                },
                updateItem: function(e, t, i) {
                    this.status.loading || (this.loading(!0), theme.cart.changeItem(t, i).then(function(e) {
                        this.updateSuccess(e)
                    }.bind(this)).catch(function(e) {
                        this.updateError(e)
                    }.bind(this)).always(function() {
                        this.loading(!1)
                    }.bind(this)))
                },
                loading: function(t) {
                    this.status.loading = t, t ? e(i.container).addClass("is-loading") : e(i.container).removeClass("is-loading")
                },
                emptyCart: function() {
                    e(i.container).empty()
                },
                updateSuccess: function(e) {
                    this.buildCart(e)
                },
                updateError: function(e) {
                    e.responseJSON && e.responseJSON.description
                },
                sizeFooter: function() {
                    if (e(i.drawer).hasClass("drawer--has-fixed-footer")) {
                        var t = e(i.drawer).find(i.fixedFooter).removeAttr("style"),
                            n = e(i.drawer).find(i.fixedInnerContent).removeAttr("style"),
                            a = t.outerHeight();
                        n.css("bottom", a), t.css("height", a)
                    }
                },
                updateCartNotification: function(t, n) {
                    n.items.length > 0 ? e(i.cartBubble).addClass("cart-link__bubble--visible") : e(i.cartBubble).removeClass("cart-link__bubble--visible")
                }
            }), n
        }(), theme.AjaxProduct = function() {
            var t = {
                loading: !1
            };

            function i(e) {
                this.$form = e, this.$addToCart = this.$form.find(".add-to-cart"), this.$form.length && this.$form.on("submit", this.addItemFromForm.bind(this))
            }
            return i.prototype = e.extend({}, i.prototype, {
                addItemFromForm: function(e, i) {
                    
                    if (e.preventDefault(), !t.loading) {
                        this.$addToCart.addClass("btn--loading"), t.loading = !0;
                        var n = this.$form.serialize();
                        theme.cart.addItemFromForm(n).then(function(e) {
                            this.success(e)
                        }.bind(this)).catch(function(e) {
                            this.error(e)
                        }.bind(this)).always(function() {
                            t.loading = !1, this.$addToCart.removeClass("btn--loading")
                        }.bind(this))
                    }
                },
                success: function(t) {
                    this.$form.find(".errors").remove(), e("body").trigger("added.ajaxProduct")
                },
                error: function(e) {
                    this.$form.find(".errors").remove(), e.responseJSON && e.responseJSON.description && this.$form.prepend('<div class="errors text-center">' + e.responseJSON.description + "</div>")
                }
            }), i
        }(), theme.collapsibles = function() {
            var t = {
                    trigger: ".collapsible-trigger",
                    module: ".collapsible-content",
                    moduleInner: ".collapsible-content__inner"
                },
                i = {
                    hide: "hide",
                    open: "is-open",
                    autoHeight: "collapsible--auto-height"
                },
                n = ".collapsible",
                a = !1;

            function o(e, t, n, o) {
                if (e.removeClass(i.hide).prepareTransition().css("height", t).toggleClass(i.open, !n), !n && o) {
                    var s = e;
                    window.setTimeout(function() {
                        s.css("height", "auto"), a = !1
                    }, 500)
                } else a = !1
            }
            return {
                init: function() {
                    e(t.trigger).each(function() {
                        var t = e(this),
                            n = t.hasClass(i.open);
                        t.attr("aria-expanded", n)
                    }), e("body, .modal__inner").off(n).on("click" + n, t.trigger, function() {
                        if (!a) {
                            a = !0;
                            var n = e(this),
                                s = n.hasClass(i.open),
                                r = n.attr("aria-controls"),
                                c = e("#" + r),
                                d = c.find(t.moduleInner).outerHeight(),
                                l = n.hasClass(i.autoHeight);
                            s && l && setTimeout(function() {
                                o(c, d = 0, s, l)
                            }, 0), s && !l && (d = 0), n.attr("aria-expanded", !s).toggleClass(i.open, !s), o(c, d, s, l)
                        }
                    })
                }
            }
        }(), theme.headerNav = function() {
            var t, i, n, a = e(document.documentElement).add("body"),
                o = e("#MainContent"),
                s = {
                    wrapper: ".header-wrapper",
                    siteHeader: ".site-header",
                    searchBtn: ".js-search-header",
                    closeSearch: ".js-search-header-close",
                    searchContainer: ".site-header__search-container",
                    logoContainer: ".site-header__logo",
                    logo: ".site-header__logo img",
                    navigation: ".site-navigation",
                    navContainerWithLogo: ".header-item--logo",
                    navItems: ".site-nav__item",
                    navLinks: ".site-nav__link",
                    navLinksWithDropdown: ".site-nav__link--has-dropdown",
                    navDropdownLinks: ".site-nav__dropdown-link--second-level"
                },
                r = {
                    hasDropdownClass: "site-nav--has-dropdown",
                    hasSubDropdownClass: "site-nav__deep-dropdown-trigger",
                    dropdownActive: "is-focused"
                },
                c = {
                    namespace: ".siteNav",
                    wrapperOverlayed: !1,
                    overlayedClass: "is-light",
                    overlayEnabledClass: "header-wrapper--overlay",
                    stickyEnabled: !1,
                    stickyActive: !1,
                    stickyClass: "site-header--stuck",
                    openTransitionClass: "site-header--opening",
                    lastScroll: 0
                };

            function d() {
                e(document.activeElement).trigger("blur"), a.removeClass("js-drawer-open js-drawer-open--search").off(c.namespace), e(s.searchContainer).removeClass("is-active"), slate.a11y.removeTrapFocus({
                    $container: e(s.searchContainer),
                    namespace: "header_search"
                }), slate.a11y.unlockMobileScrolling(c.namespace), o.off("click" + c.namespace), a.off("keyup" + c.namespace)
            }

            function l() {
                e(s.logo).each(function() {
                    var t = e(this),
                        i = t.width(),
                        n = t.closest(".grid__item").width();
                    i > n ? t.css("maxWidth", n) : t.removeAttr("style")
                })
            }

            function h() {
                e(".site-header-sticky").css("height", n.outerHeight(!0))
            }

            function u() {
                var e = t.scrollTop();
                if (e > 250) {
                    if (c.stickyActive) return;
                    c.stickyActive = !0, n.addClass(c.stickyClass), c.wrapperOverlayed && i.removeClass(c.overlayedClass), setTimeout(function() {
                        n.addClass(c.openTransitionClass)
                    }, 100)
                } else {
                    if (!c.stickyActive) return;
                    c.stickyActive = !1, n.removeClass(c.openTransitionClass).removeClass(c.stickyClass), c.wrapperOverlayed && i.addClass(c.overlayedClass)
                }
                c.lastScroll = e
            }
            return {
                init: function() {
                    t = e(window), e(s.navContainerWithLogo), e(s.logoContainer), e(s.navigation), i = e(s.wrapper), n = e(s.siteHeader), c.stickyEnabled = n.data("sticky"), c.stickyEnabled && (c.wrapperOverlayed = i.hasClass(c.overlayedClass), c.lastScroll = 0, n.wrap('<div class="site-header-sticky"></div>'), h(), t.on("resize" + c.namespace, e.debounce(50, h)), t.on("scroll" + c.namespace, e.throttle(15, u)), Shopify && Shopify.designMode && setTimeout(function() {
                            h()
                        }, 250)), theme.settings.overlayHeader = n.data("overlay"), theme.settings.overlayHeader && Shopify.designMode && e("body").hasClass("template-collection") && !e(".collection-hero").length && this.disableOverlayHeader(),
                        function() {
                            var t = !1,
                                i = !1,
                                n = !1;

                            function a(i) {
                                var a = i.parent();
                                if (a.hasClass(r.hasDropdownClass) && (a.addClass(r.dropdownActive), t = !0), !theme.config.isTouch && !n) {
                                    var o = theme.config.isTouch ? "touchend" : "click";
                                    n = !0, e("body").on(o + c.namespace, function() {
                                        d(), e("body").off(c.namespace), n = !1
                                    })
                                }
                            }

                            function o(e, t) {
                                var n = e.parent();
                                (n.hasClass(r.hasSubDropdownClass) || t) && (n.addClass(r.dropdownActive), i = !0)
                            }

                            function d() {
                                l(), h()
                            }

                            function l() {
                                e(s.navItems).removeClass(r.dropdownActive)
                            }

                            function h() {
                                e(s.navDropdownLinks).parent().removeClass(r.dropdownActive)
                            }
                            theme.config.isTouch && (e(s.navLinksWithDropdown).on("touchend" + c.namespace, function(t) {
                                var i = e(this),
                                    n = i.parent();
                                n.hasClass(r.dropdownActive) ? window.location.replace(i.attr("href")) : (t.preventDefault(), d(), a(i))
                            }), e(s.navDropdownLinks).on("touchend" + c.namespace, function(t) {
                                var i = e(this),
                                    n = i.parent();
                                n.hasClass(r.hasSubDropdownClass) ? n.hasClass(r.dropdownActive) ? window.location.replace(i.attr("href")) : (t.preventDefault(), h(), o(i)) : window.location.replace(i.attr("href"))
                            })), e(s.navLinks).on("focusin mouseover" + c.namespace, function() {
                                t && l(), i && h(), a(e(this))
                            }), e(s.navLinks).on("mouseleave" + c.namespace, function() {
                                d()
                            }), e(s.navDropdownLinks).on("focusin" + c.namespace, function() {
                                h(), o(e(this), !0)
                            })
                        }(), e(s.searchBtn).on("click" + c.namespace, function(t) {
                            t.preventDefault(), e(s.searchContainer).addClass("is-active"), a.addClass("js-drawer-open js-drawer-open--search"), slate.a11y.trapFocus({
                                $container: e(s.searchContainer),
                                namespace: "header_search",
                                $elementToFocus: e(s.searchContainer).find("input")
                            }), theme.config.bpSmall && c.stickyEnabled && c.lastScroll < 300 && window.scrollTo(0, 0), slate.a11y.lockMobileScrolling(c.namespace), o.on("click" + c.namespace, function() {
                                return d(), !1
                            }), a.on("keyup" + c.namespace, function(e) {
                                27 === e.keyCode && d()
                            })
                        }), e(s.closeSearch).on("click" + c.namespace, function() {
                            d()
                        }), t.on("load" + c.namespace, l), t.on("resize" + c.namespace, e.debounce(150, l))
                },
                disableOverlayHeader: function() {
                    e(s.wrapper).removeClass(c.overlayEnabledClass).removeClass(c.overlayedClass), c.wrapperOverlayed = !1
                },
                unload: function() {
                    e(window).off(c.namespace), e(s.searchBtn).off(c.namespace), e(s.closeSearch).off(c.namespace), a.off(c.namespace), e(s.navLinks).off(c.namespace), e(s.navDropdownLinks).off(c.namespace)
                }
            }
        }(), theme.articleImages = function() {
            var t = {};

            function i() {
                t.$rteImages.find("img").each(function() {
                    var i = e(this),
                        n = i.attr("style");
                    n && "float: none;" != n || i.width() < t.$rteImages.width() && i.addClass("rte__no-indent")
                })
            }
            return {
                init: function() {
                    t.$rteImages = e(".rte--indented-images"), t.$rteImages.length && e(window).on("load", i)
                }
            }
        }(), theme.Slideshow = function() {
            this.$slideshow = null;
            var t = {
                next: "is-next",
                init: "is-init",
                animateOut: "animate-out",
                wrapper: "slideshow-wrapper",
                slideshow: "slideshow",
                allSlides: "slick-slide",
                currentSlide: "slick-current",
                pauseButton: "slideshow__pause",
                isPaused: "is-paused"
            };

            function i(i, n) {
                this.$slideshow = e(i), this.$wrapper = this.$slideshow.closest("." + t.wrapper), this.$pause = this.$wrapper.find("." + t.pauseButton), this.settings = {
                    accessibility: !0,
                    arrows: !!n.arrows,
                    dots: !!n.dots,
                    fade: !!n.fade,
                    speed: n.speed ? n.speed : 500,
                    draggable: !0,
                    touchThreshold: 5,
                    pauseOnHover: !1,
                    autoplay: this.$slideshow.data("autoplay"),
                    autoplaySpeed: this.$slideshow.data("speed")
                }, this.$slideshow.on("init", this.init.bind(this)), this.settings.fade && (this.$slideshow.on("beforeChange", this.beforeSlideChange.bind(this)), this.$slideshow.on("afterChange", this.afterSlideChange.bind(this))), this.$slideshow.slick(this.settings), this.$pause.on("click", this._togglePause.bind(this))
            }
            return i.prototype = e.extend({}, i.prototype, {
                init: function(e, i) {
                    this.$slideshowList = i.$list, this.$slickDots = i.$dots, this.$allSlides = i.$slides, this.slideCount = i.slideCount, this.$slideshow.addClass(t.init), this._a11y(), this._clonedLazyloading()
                },
                beforeSlideChange: function(e, i, n, a) {
                    i.$slider.find("." + t.currentSlide).addClass(t.animateOut)
                },
                afterSlideChange: function(e, i, n) {
                    i.$slider.find("." + t.allSlides).removeClass(t.animateOut)
                },
                destroy: function() {
                    this.$slideshow.slick("unslick")
                },
                _play: function() {
                    this.$slideshow.slick("slickPause"), e(t.pauseButton).addClass("is-paused")
                },
                _pause: function() {
                    this.$slideshow.slick("slickPlay"), e(t.pauseButton).removeClass("is-paused")
                },
                _togglePause: function() {
                    var i = this._getSlideshowId(this.$pause);
                    this.$pause.hasClass(t.isPaused) ? (this.$pause.removeClass(t.isPaused), e(i).slick("slickPlay")) : (this.$pause.addClass(t.isPaused), e(i).slick("slickPause"))
                },
                _getSlideshowId: function(e) {
                    return "#Slideshow-" + e.data("id")
                },
                _activeSlide: function() {
                    return this.$slideshow.find(".slick-active")
                },
                _currentSlide: function() {
                    return this.$slideshow.find(".slick-current")
                },
                _nextSlide: function(e) {
                    return this.$slideshow.find('.slideshow__slide[data-slick-index="' + e + '"]')
                },
                _a11y: function() {
                    var i = this.$slideshowList,
                        n = this.settings.autoplay;
                    i && (i.removeAttr("aria-live"), e(t.wrapper).on("focusin", function(a) {
                        e(t.wrapper).has(a.target).length && (i.attr("aria-live", "polite"), n && this._pause())
                    }.bind(this)), e(t.wrapper).on("focusout", function(a) {
                        e(t.wrapper).has(a.target).length && (i.removeAttr("aria-live"), n && this._play())
                    }.bind(this)))
                },
                _clonedLazyloading: function() {
                    var t = this.$slideshow;
                    t.find(".slick-slide").each(function(i, n) {
                        var a = e(n);
                        if (a.hasClass("slick-cloned")) {
                            var o = a.data("id"),
                                s = a.find(".hero__image").removeClass("lazyloading").addClass("lazyloaded");
                            setTimeout(function() {
                                var e = t.find(".slideshow__slide--" + o + ":not(.slick-cloned) .hero__image").attr("style");
                                e && s.attr("style", e)
                            }, this.settings.autoplaySpeed / 1.5)
                        }
                    }.bind(this))
                }
            }), i
        }(), theme.announcementBar = function() {
            var t = {
                    bar: ".announcement",
                    text: ".announcement__text",
                    closeBtn: ".announcement__close"
                },
                i = {
                    opening: "announcement--opening",
                    closed: "announcement--closed"
                },
                n = {
                    namespace: ".announcementBar"
                };

            function a() {
                e(t.bar).removeClass(i.closed), o()
            }

            function o() {
                var n = e(t.text).outerHeight(!0);
                e(t.bar).addClass(i.opening).css({
                    "max-height": n
                })
            }

            function s() {
                return e(t.text).data("text")
            }
            return {
                init: function() {
                    e(t.closeBtn).length && (theme.config.hasSessionStorage && "hidden" !== sessionStorage[s()] && window.setTimeout(function() {
                        a()
                    }, 2e3), theme.config.hasSessionStorage || window.setTimeout(function() {
                        a()
                    }, 2e3), e(t.closeBtn).on("click", function(n) {
                        n.preventDefault(), theme.config.hasSessionStorage && sessionStorage.setItem(s(), "hidden"), e(t.bar).addClass(i.closed)
                    }), e(window).on("resize" + n.namespace, e.debounce(150, o)))
                },
                unload: function() {
                    e(window).off(n.namespace)
                }
            }
        }(), theme.currencySwitcher = function() {
            var t, i = {
                    dataDiv: "#CurrencyData",
                    currencyOptions: ".currency-options__btn",
                    pickerFlag: ".currency-picker .currency-flag",
                    pickerLabel: ".currency-picker .currency-picker__label"
                },
                n = {};

            function a() {
                var a = e(this).data("value");
                if (theme.settings.nativeMultiCurrency) return e(this).addClass("is-active"), void theme.cart.updateCurrency(a);
                a !== n.currency && (n.currency = a, e(i.dataDiv).data("current-currency", a), o(a), s()), t.close()
            }

            function o(t) {
                e(i.pickerFlag).attr("data-flag", t), e(i.pickerLabel).text(t), e(i.currencyOptions).removeClass("is-active"), e(i.currencyOptions + "[data-value=" + t + "]").addClass("is-active")
            }

            function s() {
                if (!theme.settings.nativeMultiCurrency) {
                    var t = e(i.dataDiv).data("current-currency");
                    Currency.convertAll(Currency.currentCurrency, t)
                }
            }
            return {
                init: function() {
                    var s = e(i.dataDiv);
                    s.length && (t = new theme.Modals("CurrencyModal", "currency-modal", {
                        closeOffContentClick: !1
                    }), e(i.currencyOptions).on("click", a), n = {
                        currency: s.data("shop-currency"),
                        format: s.data("format"),
                        moneyFormat: s.data("money-format"),
                        moneyCurrencyFormat: s.data("money-currency-format")
                    }, theme.settings.nativeMultiCurrency || (Currency.format = n.format, Currency.money_format[n.currency] = n.moneyFormat, Currency.money_with_currency_format[n.currency] = n.moneyCurrencyFormat, e("span.money span.money").each(function() {
                        e(this).parents("span.money").removeClass("money")
                    }), e("span.money").each(function() {
                        e(this).attr("data-currency-" + n.currency, e(this).html())
                    }), r = Currency.cookie.read(), null == r ? Currency.currentCurrency = r = n.currency : e(i.currencyOptions).length && 0 === e(i.currencyOptions + "[data-value=" + r + "]").length ? (Currency.currentCurrency = n.currency, Currency.cookie.write(n.currency)) : r === n.currency ? Currency.currentCurrency = n.currency : Currency.convertAll(n.currency, r), e(i.dataDiv).data("current-currency", r), n.currency = r, o(r)));
                    var r
                },
                refresh: s,
                ajaxrefresh: function() {
                    if (!theme.settings.nativeMultiCurrency) {
                        var t = e(i.dataDiv).data("shop-currency"),
                            n = e(i.dataDiv).data("current-currency");
                        Currency.convertAll(t, n)
                    }
                }
            }
        }(), theme.predictiveSearch = function() {
            var t, i = "",
                n = !1,
                a = ".predictive",
                o = {
                    form: "#HeaderSearchForm",
                    input: 'input[type="search"]',
                    wrapper: ".predictive-results",
                    searchButton: "[data-predictive-search-button]",
                    searchTerm: "[data-predictive-search-term]",
                    resultDiv: "#PredictiveResults",
                    resultTemplate: "#PredictiveTemplate"
                },
                s = {
                    up_arrow: 38,
                    down_arrow: 40,
                    tab: 9
                };

            function r() {
                e(o.wrapper).addClass("hide"), clearTimeout(t)
            }

            function c() {
                e(o.form).trigger("submit")
            }

            function d(a) {
                a.keyCode !== s.up_arrow && a.keyCode !== s.down_arrow && a.keyCode !== s.tab && function() {
                    var a = e(o.input, o.form).val();
                    if ("" === a) return void r();
                    var s = function(e) {
                        if ("string" != typeof e) return null;
                        return e.trim().replace(/\ /g, "-").toLowerCase()
                    }(a);
                    clearTimeout(t), t = setTimeout(function() {
                        ! function(t) {
                            if (n) return;
                            if (i === t) return;
                            i = t, n = !0, jQuery.getJSON("/search/suggest.json", {
                                q: t,
                                resources: {
                                    type: theme.settings.predictiveSearchType,
                                    limit: 3,
                                    options: {
                                        unavailable_products: "last",
                                        fields: "title,product_type,variants.title,vendor"
                                    }
                                }
                            }).done(function(i) {
                                n = !1;
                                var a = {},
                                    s = 0;
                                e(o.wrapper).removeClass("hide");
                                var c = Object.entries(i.resources.results);
                                for (var d of c) {
                                    var u = d[0],
                                        p = d[1];
                                    switch (s += p.length, u) {
                                        case "products":
                                            a[u] = l(p);
                                            break;
                                        default:
                                            a[u] = h(p)
                                    }
                                }
                                0 === s && r(), e(o.resultDiv).empty().append(resultTemplate(a)), e(o.searchTerm, o.wrapper).text(t)
                            })
                        }(s)
                    }.bind(this), 500)
                }()
            }

            function l(e) {
                for (var t = [], i = e.length - 1; i >= 0; i--) {
                    var n = e[i],
                        a = {
                            title: n.title,
                            url: n.url,
                            image: theme.Images.getSizedImageUrl(n.image, "200x200_crop_center"),
                            vendor: n.vendor,
                            price: theme.Currency.formatMoney(n.price),
                            compare_price_max: theme.Currency.formatMoney(n.compare_at_price_max),
                            on_sale: parseInt(n.compare_at_price_max) > parseInt(n.price)
                        };
                    t.push(a)
                }
                return t
            }

            function h(e) {
                for (var t = e.length - 1; t >= 0; t--)
                    if (e[t].image) {
                        var i = theme.Images.getSizedImageUrl(e[t].image, "200x200_crop_center");
                        e[t].image = i
                    }
                return e
            }
            return {
                init: function() {
                    if (theme.settings.predictiveSearch) {
                        if (document.getElementById("shopify-features") && !JSON.parse(document.getElementById("shopify-features").innerHTML).predictiveSearch) return;
                        e(o.form).attr("autocomplete", "off"), e(o.input, o.form).on("keyup" + a, d), e(o.searchButton, o.wrapper).on("click" + a, c);
                        var t = e(o.resultTemplate).html();
                        resultTemplate = Handlebars.compile(t)
                    }
                }
            }
        }(), theme.initQuickShop = function(t) {
            var i = [];
            e(".quick-product__btn").each(function() {
                var t = e(this).data("product-id"),
                    n = "QuickShopModal-" + t,
                    a = "quick-modal-" + t;
                i.indexOf(t) > -1 ? e('.modal--quick-shop[data-product-id="' + t + '"]').each(function(t) {
                    t > 0 && e(this).remove()
                }) : (new theme.Modals(n, a), i.push(t))
            })
        }, theme.videoModal = function() {
            var t = {
                    width: 1280,
                    height: 720,
                    playerVars: {
                        autohide: 0,
                        autoplay: 1,
                        branding: 0,
                        cc_load_policy: 0,
                        fs: 0,
                        iv_load_policy: 3,
                        modestbranding: 1,
                        playsinline: 1,
                        quality: "hd720",
                        rel: 0,
                        showinfo: 0,
                        wmode: "opaque"
                    }
                },
                i = 'a[href*="youtube.com/watch"], a[href*="youtu.be/"]';
            if (e(i).length) {
                var n = new theme.Modals("VideoModal", "video-modal", {
                    closeOffContentClick: !0,
                    solid: !0
                });
                e(i).on("click", function(t) {
                    t.preventDefault(), window.loadYouTube(), theme.config.youTubeReady ? a(t) : e("body").on("youTubeReady", function() {
                        a(t)
                    })
                })
            }

            function a(i) {
                var a, o, s = e(i.currentTarget),
                    r = (a = s.attr("href"), !(!(o = a.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/)) || 11 != o[7].length) && o[7]),
                    c = e.extend({}, t, {
                        videoId: r
                    });
                c.playerVars.playsinline = theme.config.bpSmall ? 0 : 1;
                var d = new YT.Player("VideoHolder", c);
                n.open(), e("body").on("modalClose.VideoModal", function() {
                    setTimeout(function() {
                        d.destroy()
                    }, 500)
                })
            }
        }, theme.parallaxSections = {}, theme.Parallax = function() {
            function t(t, i) {
                this.$container = e(t), this.namespace = i.namespace, this.$container.length && (i.desktopOnly ? this.desktopInit() : this.init(this.$container, i))
            }
            return t.prototype = e.extend({}, t.prototype, {
                init: function(t) {
                    var i = this.$window = e(window),
                        n = this.$container.offset().top;
                    i.on("scroll" + this.namespace, function(e) {
                        var t = i.scrollTop(),
                            a = (n - t) / 7;
                        this.$container.css({
                            transform: "translate3d(0, " + a + "px, 0)"
                        })
                    }.bind(this)), i.on("resize" + this.namespace, e.debounce(350, function() {
                        i.off(this.namespace), !t || theme.config.bpSmall ? this.init() : this.init(!0)
                    }.bind(this)))
                },
                desktopInit: function() {
                    theme.config.bpSmall || this.init(!0), e("body").on("matchSmall", function() {
                        this.destroy()
                    }.bind(this)), e("body").on("matchLarge", function() {
                        this.init(!0)
                    }.bind(this))
                },
                destroy: function() {
                    this.$container.removeAttr("style"), this.$window.off(this.namespace)
                }
            }), t
        }(), theme.customerTemplates = function() {
            function t() {
                e("#RecoverPasswordForm").toggleClass("hide"), e("#CustomerLoginForm").toggleClass("hide")
            }
            return {
                init: function() {
                    var i, n;
                    "#recover" === window.location.hash && t(), e("#RecoverPassword").on("click", function(e) {
                        e.preventDefault(), t()
                    }), e("#HideRecoverPasswordLink").on("click", function(e) {
                        e.preventDefault(), t()
                    }), e(".reset-password-success").length && e("#ResetSuccess").removeClass("hide"), i = e("#AddressNewForm"), n = e(".js-address-form"), i.length && n.length && (Shopify && e(".js-address-country").each(function() {
                        var t = e(this),
                            i = t.data("country-id"),
                            n = t.data("province-id"),
                            a = t.data("province-container-id");
                        new Shopify.CountryProvinceSelector(i, n, {
                            hideElement: a
                        })
                    }), e(".address-new-toggle").on("click", function() {
                        i.toggleClass("hide")
                    }), e(".address-edit-toggle").on("click", function() {
                        var t = e(this).data("form-id");
                        e("#EditAddress_" + t).toggleClass("hide")
                    }), e(".address-delete").on("click", function() {
                        var t = e(this),
                            i = t.data("form-id"),
                            n = t.data("confirm-message");
                        confirm(n || "Are you sure you wish to delete this address?") && Shopify.postLink("/account/addresses/" + i, {
                            parameters: {
                                _method: "delete"
                            }
                        })
                    }))
                }
            }
        }(), theme.Product = function() {
            var t, i, n = {
                    onSale: "on-sale",
                    disabled: "disabled",
                    isModal: "is-modal",
                    loading: "loading",
                    loaded: "loaded",
                    interactable: "video-interactable",
                    visuallyHide: "visually-invisible"
                },
                a = {
                    productVideo: ".product__video",
                    videoParent: ".product__video-wrapper",
                    currentSlide: ".slick-current"
                },
                o = {},
                s = [],
                r = {
                    height: "480",
                    width: "850",
                    playerVars: {
                        autohide: 0,
                        autoplay: 1,
                        branding: 0,
                        cc_load_policy: 0,
                        controls: 0,
                        fs: 0,
                        iv_load_policy: 3,
                        modestbranding: 1,
                        playsinline: 1,
                        quality: "hd720",
                        rel: 0,
                        showinfo: 0,
                        wmode: "opaque"
                    },
                    events: {
                        onReady: function(t) {
                            var i = e(t.target.a),
                                n = i.attr("id");
                            s[n] = t.target;
                            s[n];
                            u(i), "muted" === o[n].style ? s[n].mute().playVideo().pauseVideo() : p(i);
                            (i.closest(a.currentSlide).length || 1 === i.data("image-count")) && "muted" === o[n].style && (s[n].playVideo(), l(n))
                        },
                        onStateChange: function(t) {
                            var i = e(t.target.a),
                                r = i.attr("id"),
                                c = s[r];
                            switch (t.data) {
                                case -1:
                                    o[r].attemptedToPlay && (p(i), i.closest(a.videoParent).addClass(n.interactable));
                                    break;
                                case 0:
                                    c.playVideo();
                                    break;
                                case 1:
                                    p(i);
                                    break;
                                case 3:
                                    o[r].attemptedToPlay = !0
                            }
                        }
                    }
                },
                c = [],
                d = {
                    byline: !1,
                    title: !1,
                    portrait: !1,
                    loop: !0
                };

            function l(t) {
                h(t), e(window).on("scroll." + t, {
                    id: t
                }, e.throttle(150, h))
            }

            function h(t) {
                var i;
                if (i = "string" == typeof t ? t : t.data.id, theme.isElementVisible(e("#" + i))) {
                    if (o[i] && "unmuted" === o[i].style) return;
                    ! function(e) {
                        s[e] && "function" == typeof s[e].playVideo && s[e].playVideo()
                    }(i)
                } else ! function(e) {
                    s[e] && "function" == typeof s[e].pauseVideo && s[e].pauseVideo()
                }(i)
            }

            function u(e) {
                e.closest(a.videoParent).addClass(n.loading)
            }

            function p(e) {
                e.closest(a.videoParent).removeClass(n.loading).addClass(n.loaded)
            }

            function m(t) {
                var i = this.$container = e(t),
                    n = this.sectionId = i.attr("data-section-id");
                this.inModal = i.closest(".modal").length, this.$modal, this.settings = {
                    enableHistoryState: i.data("enable-history-state") || !1,
                    namespace: ".product-" + n,
                    inventory: i.data("inventory") || !1,
                    incomingInventory: i.data("incoming-inventory") || !1,
                    modalInit: !1,
                    slickMainInitialized: !1,
                    slickThumbInitialized: !1,
                    thumbArrows: !1,
                    thumbVertical: !1,
                    hasImages: !0,
                    hasMultipleImages: !1,
                    imageSize: "620x"
                }, this.inModal && (this.settings.enableHistoryState = !1, this.settings.namespace = ".product-" + n + "-modal", this.$modal = e("#QuickShopModal-" + n)), this.selectors = {
                    variantsJson: "VariantsJson-" + n,
                    currentVariantJson: "CurrentVariantJson-" + n,
                    video: "ProductVideo-" + n,
                    photoThumbs: ".product__thumb-" + n,
                    thumbSlider: "#ProductThumbs-" + n,
                    mainSlider: "#ProductPhotos-" + n,
                    imageContainer: "[data-product-images]",
                    productImageMain: ".product-image-main--" + n,
                    priceWrapper: ".product__price-wrap-" + n,
                    price: "#ProductPrice-" + n,
                    comparePrice: "#ComparePrice-" + n,
                    priceA11y: "#PriceA11y-" + n,
                    comparePriceA11y: "#ComparePriceA11y-" + n,
                    unitWrapper: ".product__unit-price-wrapper--" + n,
                    unitPrice: ".product__unit-price--" + n,
                    unitPriceBaseUnit: ".product__unit-base--" + n,
                    sku: "#Sku-" + n,
                    inventory: "#ProductInventory-" + n,
                    incomingInventory: "#ProductIncomingInventory-" + n,
                    addToCart: "#AddToCart-" + n,
                    addToCartText: "#AddToCartText-" + n,
                    originalSelectorId: "#ProductSelect-" + n,
                    singleOptionSelector: ".variant__input-" + n,
                    variantColorSwatch: ".variant__input--color-swatch-" + n,
                    modalFormHolder: "#ProductFormHolder-" + n,
                    formContainer: "#AddToCartForm-" + n
                }, this.$mainSlider = e(this.selectors.mainSlider), this.$thumbSlider = e(this.selectors.thumbSlider), this.$firstProductImage = this.$mainSlider.find("img").first(), this.$firstProductImage.length || (this.settings.hasImages = !1), this.init()
            }
            return m.prototype = e.extend({}, m.prototype, {
                init: function() {
                    this.inModal && (this.$container.addClass(n.isModal), e("body").off("modalOpen.QuickShopModal-" + this.sectionId).off("modalClose.QuickShopModal-" + this.sectionId), e("body").on("modalOpen.QuickShopModal-" + this.sectionId, this.openModalProduct.bind(this)), e("body").on("modalClose.QuickShopModal-" + this.sectionId, this.closeModalProduct.bind(this))), this.inModal || (this.stringOverrides(), this.formSetup(), this.productSetup(), this.checkIfVideos(), this.createImageCarousels())
                },
                formSetup: function() {
                    theme.settings.dynamicVariantsEnable && (this.$variantSelectors = e(this.selectors.formContainer).find(this.selectors.singleOptionSelector)), this.initQtySelector(), this.initAjaxProductForm(), this.initVariants()
                },
                productSetup: function() {
                    this.setImageSizes(), this.initImageSwitch(), this.initImageZoom()
                },
                stringOverrides: function() {
                    theme.productStrings = theme.productStrings || {}, e.extend(theme.strings, theme.productStrings)
                },
                setImageSizes: function() {
                    if (this.settings.hasImages) {
                        var e = this.$firstProductImage[0].currentSrc;
                        e && (this.settings.imageSize = theme.Images.imageSize(e))
                    }
                },
                initVariants: function() {
                    if (document.getElementById(this.selectors.variantsJson)) {
                        this.variantsObject = JSON.parse(document.getElementById(this.selectors.variantsJson).innerHTML);
                        var t = {
                            $container: this.$container,
                            enableHistoryState: this.settings.enableHistoryState,
                            singleOptionSelector: this.selectors.singleOptionSelector,
                            originalSelectorId: this.selectors.originalSelectorId,
                            variants: this.variantsObject
                        };
                        e(this.selectors.variantColorSwatch).length && e(this.selectors.variantColorSwatch).on("change", function(t) {
                            var i = e(t.currentTarget),
                                n = i.data("color-name"),
                                a = i.data("color-index");
                            this.updateColorName(n, a)
                        }.bind(this)), this.variants = new slate.Variants(t), this.$container.on("variantChange" + this.settings.namespace, this.updateCartButton.bind(this)).on("variantImageChange" + this.settings.namespace, this.updateVariantImage.bind(this)).on("variantPriceChange" + this.settings.namespace, this.updatePrice.bind(this)).on("variantUnitPriceChange" + this.settings.namespace, this.updateUnitPrice.bind(this)), e(this.selectors.sku).length && this.$container.on("variantSKUChange" + this.settings.namespace, this.updateSku.bind(this)), (this.settings.inventory || this.settings.incomingInventory) && this.$container.on("variantChange" + this.settings.namespace, this.updateInventory.bind(this)), theme.settings.dynamicVariantsEnable && document.getElementById(this.selectors.currentVariantJson) && (this.currentVariantObject = JSON.parse(document.getElementById(this.selectors.currentVariantJson).innerHTML), this.$variantSelectors.on("change" + this.settings.namespace, this.updateVariantAvailability.bind(this)), this.setCurrentVariantAvailability(this.currentVariantObject, !0))
                    }
                },
                updateColorName: function(t, i) {
                    e("#VariantColorLabel-" + this.sectionId + "-" + i).text(t)
                },
                setCurrentVariantAvailability: function(t) {
                    var i = {
                        option1: [],
                        option2: [],
                        option3: []
                    };
                    this.disableVariantGroup(e(this.selectors.formContainer).find(".variant-input-wrap"));
                    var n = this.variantsObject.filter(function(e) {
                            return t.id !== e.id && (t.option2 === e.option2 && t.option3 === e.option3 || (t.option1 === e.option1 && t.option3 === e.option3 || (t.option1 === e.option1 && t.option2 === e.option2 || void 0)))
                        }),
                        a = {
                            variant: t
                        };
                    for (var o in n = Object.assign({}, a, n))
                        if (n.hasOwnProperty(o)) {
                            var s = n[o],
                                r = s.option1,
                                c = s.option2,
                                d = s.option3;
                            r && -1 === i.option1.indexOf(r) && i.option1.push(r), c && -1 === i.option2.indexOf(c) && i.option2.push(c), d && -1 === i.option3.indexOf(d) && i.option3.push(d)
                        }
                    i.option1.length && this.enableVariantOptionByValue(i.option1, "option1"), i.option2.length && this.enableVariantOptionByValue(i.option2, "option2"), i.option3.length && this.enableVariantOptionByValue(i.option3, "option3")
                },
                updateVariantAvailability: function(t, i, n) {
                    if (i && n) var a = i,
                        o = n;
                    else {
                        var s = e(t.currentTarget);
                        a = s.val() ? s.val() : t.currentTarget.value, o = s.data("index")
                    }
                    var r = this.variantsObject.filter(function(e) {
                        return e[o] === a
                    });
                    e(this.selectors.formContainer).find(".variant-input-wrap").each(function(t, i) {
                        var n = e(i),
                            a = n.data("index");
                        if (a !== o) {
                            this.disableVariantGroup(n);
                            for (var s = 0; s < r.length; s++) this.enableVariantOption(n, r[s][a])
                        }
                    }.bind(this))
                },
                disableVariantGroup: function(e) {
                    "dropdown" === theme.settings.dynamicVariantType ? e.find("option").prop("disabled", !0) : (e.find("input").prop("disabled", !0), e.find("label").toggleClass("disabled", !0))
                },
                enableVariantOptionByValue: function(t, i) {
                    for (var n = e(this.selectors.formContainer).find('.variant-input-wrap[data-index="' + i + '"]'), a = 0; a < t.length; a++) this.enableVariantOption(n, t[a])
                },
                enableVariantOption: function(e, t) {
                    if (t = t.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1"), "dropdown" === theme.settings.dynamicVariantType) e.find('option[value="' + t + '"]').prop("disabled", !1);
                    else {
                        var i = e.find('.variant-input[data-value="' + t + '"]');
                        i.find("input").prop("disabled", !1), i.find("label").toggleClass("disabled", !1)
                    }
                },
                updateCartButton: function(t) {
                    var i = t.variant;
                    i ? i.available ? (e(this.selectors.addToCart).removeClass(n.disabled).prop("disabled", !1), e(this.selectors.addToCartText).html(theme.strings.addToCart)) : (e(this.selectors.addToCart).addClass(n.disabled).prop("disabled", !0), e(this.selectors.addToCartText).html(theme.strings.soldOut)) : (e(this.selectors.addToCart).addClass(n.disabled).prop("disabled", !0), e(this.selectors.addToCartText).html(theme.strings.unavailable))
                },
                updatePrice: function(t) {
                    var i = t.variant;
                    i && (e(this.selectors.price).html(theme.Currency.formatMoney(i.price, theme.settings.moneyFormat)).show(), i.compare_at_price > i.price ? (e(this.selectors.comparePrice).html(theme.Currency.formatMoney(i.compare_at_price, theme.settings.moneyFormat)), e(this.selectors.priceWrapper).removeClass("hide"), e(this.selectors.price).addClass(n.onSale), e(this.selectors.comparePriceA11y).attr("aria-hidden", "false"), e(this.selectors.priceA11y).attr("aria-hidden", "false")) : (e(this.selectors.priceWrapper).addClass("hide"), e(this.selectors.price).removeClass(n.onSale), e(this.selectors.comparePriceA11y).attr("aria-hidden", "true"), e(this.selectors.priceA11y).attr("aria-hidden", "true")), theme.settings.currenciesEnabled && theme.currencySwitcher.ajaxrefresh())
                },
                updateUnitPrice: function(t) {
                    var i = t.variant;
                    i && i.unit_price ? (e(this.selectors.unitPrice).html(theme.Currency.formatMoney(i.unit_price, theme.settings.moneyFormat)), e(this.selectors.unitPriceBaseUnit).text(theme.Currency.getBaseUnit(i)), e(this.selectors.unitWrapper).removeClass("hide").removeClass(n.visuallyHide)) : e(this.selectors.unitWrapper).addClass(n.visuallyHide)
                },
                updateSku: function(t) {
                    var i = t.variant,
                        n = "";
                    i && (i.sku && (n = i.sku), e(this.selectors.sku).html(n))
                },
                updateInventory: function(e) {
                    var t = e.variant;
                    if (!t || !t.inventory_management) return this.toggleInventoryQuantity(!1), void this.toggleIncomingInventory(!1);
                    if ("shopify" === t.inventory_management && window.inventories && window.inventories[this.sectionId]) {
                        variantInventoryObject = window.inventories[this.sectionId][t.id];
                        var i = variantInventoryObject.quantity,
                            n = !0,
                            a = !1;
                        (i <= 0 || i > theme.settings.inventoryThreshold) && (n = !1), this.toggleInventoryQuantity(n, i), !n && variantInventoryObject.incoming && (a = !0), this.toggleIncomingInventory(a, t.available, variantInventoryObject.next_incoming_date)
                    }
                },
                toggleInventoryQuantity: function(t, i) {
                    this.settings.inventory || (t = !1), t ? e(this.selectors.inventory).removeClass("hide").text(theme.strings.stockLabel.replace("[count]", i)) : e(this.selectors.inventory).addClass("hide")
                },
                toggleIncomingInventory: function(t, i, n) {
                    if (this.settings.incomingInventory || (t = !1), t) {
                        var a = i ? theme.strings.willNotShipUntil.replace("[date]", n) : theme.strings.willBeInStockAfter.replace("[date]", n);
                        n || (a = theme.strings.waitingForStock), e(this.selectors.incomingInventory).removeClass("hide").text(a)
                    } else e(this.selectors.incomingInventory).addClass("hide")
                },
                updateVariantImage: function(t) {
                    var i = t.variant,
                        n = (theme.Images.getSizedImageUrl(i.featured_image.src, this.settings.imageSize), e('.product__thumb[data-id="' + i.featured_image.id + '"]')),
                        a = this._slideIndex(n.closest(".product__thumb-item"));
                    void 0 !== a && this.$mainSlider.slick("slickGoTo", a)
                },
                initImageSwitch: function() {
                    if (e(this.selectors.photoThumbs).length) {
                        e(this.selectors.photoThumbs).on("click", function(e) {
                            e.preventDefault()
                        })
                    }
                },
                initImageZoom: function() {
                    var t = e(this.selectors.imageContainer, this.$container);
                    new theme.Photoswipe(t[0], this.sectionId)
                },
                checkIfVideos: function() {
                    var t = this.$mainSlider.find(a.productVideo);
                    if (!t.length) return !1;
                    var n = [];
                    return t.each(function() {
                        var t = e(this).data("video-type");
                        n.indexOf(t) < 0 && n.push(t)
                    }), n.indexOf("youtube") > -1 && (theme.config.youTubeReady ? this.loadYoutubeVideos(t) : (window.loadYouTube(), e("body").on("youTubeReady" + this.settings.namespace, function() {
                        this.loadYoutubeVideos(t)
                    }.bind(this)))), n.indexOf("vimeo") > -1 && (i ? this.loadVimeoVideos(t) : (window.loadVimeo(), e("body").on("vimeoReady" + this.settings.namespace, function() {
                        this.loadVimeoVideos(t)
                    }.bind(this)))), n.indexOf("mp4") > -1 && this.loadMp4Videos(t), n
                },
                loadMp4Videos: function(t) {
                    t.each(function() {
                        var t = e(this);
                        if ("mp4" == t.data("video-type")) {
                            var i = t.attr("id");
                            t.data("video-id");
                            o[this.id] = {
                                type: "mp4",
                                divId: i,
                                style: t.data("video-style")
                            }
                        }
                    })
                },
                loadVimeoVideos: function(t) {
                    for (var n in t.each(function() {
                            var t = e(this);
                            if ("vimeo" == t.data("video-type")) {
                                var i = t.attr("id"),
                                    n = t.data("video-id");
                                o[this.id] = {
                                    type: "vimeo",
                                    divId: i,
                                    id: n,
                                    style: t.data("video-style"),
                                    width: t.data("video-width"),
                                    height: t.data("video-height")
                                }
                            }
                        }), o)
                        if ("vimeo" == o[n].type) {
                            var a = e.extend({}, d, o[n]);
                            c[n] = new Vimeo.Player(o[n].divId, a)
                        }
                    i = !0
                },
                autoplayVimeoVideo: function(t) {
                    theme.config.bpSmall ? p(e("#" + t)) : this.requestToPlayVimeoVideo(t)
                },
                requestToPlayVimeoVideo: function(t) {
                    u(e("#" + t)), i ? this.playVimeoVideo(t) : e("body").on("vimeoReady" + this.settings.namespace, function() {
                        this.playVimeoVideo(t)
                    }.bind(this))
                },
                playVimeoVideo: function(t) {
                    c[t].play(), "muted" === o[t].style && c[t].setVolume(0), p(e("#" + t))
                },
                stopVimeoVideo: function(e) {
                    if (theme.config.vimeoReady)
                        if (e) c[e].pause();
                        else
                            for (key in c) "function" == typeof c[key].pause && c[key].pause()
                },
                loadYoutubeVideos: function(i) {
                    for (var n in i.each(function() {
                            var t = e(this);
                            if ("youtube" == t.data("video-type")) {
                                var i = t.attr("id"),
                                    n = t.data("youtube-id");
                                o[this.id] = {
                                    type: "youtube",
                                    id: i,
                                    videoId: n,
                                    style: t.data("video-style"),
                                    width: t.data("video-width"),
                                    height: t.data("video-height"),
                                    attemptedToPlay: !1
                                }
                            }
                        }), o)
                        if ("youtube" === o[n].type && o.hasOwnProperty(n)) {
                            var a = e.extend({}, r, o[n]);
                            "muted" === a.style || (a.playerVars.controls = 1, a.playerVars.autoplay = 0), s[n] = new YT.Player(n, a)
                        }
                    t = !0
                },
                requestToPlayYoutubeVideo: function(t, i) {
                    if (theme.config.youTubeReady)
                        if (u(e("#" + t)), "function" == typeof s[t].playVideo) this.playYoutubeVideo(t, i);
                        else {
                            var n = this;
                            setTimeout(function() {
                                n.playYoutubeVideo(t, i)
                            }, 1e3)
                        }
                },
                playYoutubeVideo: function(t, i) {
                    p(e("#" + t)), "function" == typeof s[t].playVideo && s[t].playVideo(), i || l(t)
                },
                stopYoutubeVideo: function(t) {
                    if (theme.config.youTubeReady)
                        if (t && s[t]) "function" == typeof s[t].pauseVideo && s[t].pauseVideo(), e(window).off("scroll." + t);
                        else
                            for (key in s) "function" == typeof s[key].pauseVideo && (s[key].pauseVideo(), e(window).off("scroll." + key))
                },
                playMp4Video: function(t) {
                    var i = e("#" + t);
                    p(i), i[0].play()
                },
                stopMp4Video: function(t) {
                    if (t) e("#" + t)[0].pause();
                    else
                        for (var i in o)
                            if ("mp4" === o[i].type) {
                                var n = e("#" + o[i].divId)[0];
                                "function" == typeof n.pause && n.pause()
                            }
                },
                createImageCarousels: function() {
                    if (!this.$thumbSlider.length || e(this.selectors.photoThumbs).length < 2) {
                        var t = e(this.selectors.productImageMain).find(a.productVideo);
                        t.length && this.initVideo(t)
                    } else {
                        this.settings.hasMultipleImages = !0;
                        var i = this.$mainSlider.find(".starting-slide"),
                            n = this._slideIndex(i);
                        this.$mainSlider.off("init"), this.$mainSlider.off("beforeChange"), this.$mainSlider.on("init", this.mainSlideInit.bind(this)), this.$mainSlider.on("beforeChange", this.beforeSlideChange.bind(this)), this.$thumbSlider.on("init", this.thumbSlideInit.bind(this));
                        var o = this.settings.thumbVertical = "beside" === this.$thumbSlider.data("position"),
                            s = this.settings.thumbArrows = this.$thumbSlider.data("arrows");
                        this.$mainSlider.slick({
                            adaptiveHeight: !o,
                            asNavFor: this.selectors.thumbSlider,
                            infinite: !1,
                            arrows: !1,
                            dots: !1,
                            initialSlide: n
                        }), this.$thumbSlider.slick({
                            asNavFor: this.selectors.mainSlider,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: s,
                            dots: !1,
                            vertical: o,
                            verticalSwiping: o,
                            focusOnSelect: !0,
                            infinite: !1,
                            customHeightMatching: !s && o,
                            customSlideAdvancement: !0,
                            initialSlide: n
                        }), AOS && AOS.refresh()
                    }
                },
                destroyImageCarousels: function() {
                    this.$mainSlider && this.settings.slickMainInitialized && (this.$mainSlider.slick("unslick"), this.settings.slickMainInitialized = !1), this.$thumbSlider && this.settings.slickThumbInitialized && (this.$thumbSlider.slick("unslick"), this.settings.slickThumbInitialized = !1), this.settings.slickMainInitialized = !1, this.settings.slickThumbInitialized = !1
                },
                mainSlideInit: function(e, t) {
                    var i = t.$slider.find(a.currentSlide).find(a.productVideo);
                    this.settings.slickMainInitialized = !0, i.length && this.initVideo(i)
                },
                thumbSlideInit: function(e, t) {
                    this.settings.slickThumbInitialized = !0, this.settings.thumbArrows && this.$thumbSlider.on("setPosition", this.thumbSlideArrowVisibility.bind(this))
                },
                thumbSlideArrowVisibility: function(e, t) {
                    var i = t.$slider,
                        n = i.find(".slick-arrow"),
                        a = !1;
                    this.settings.thumbVertical ? i.find(".slick-track").height() >= i.height() && (a = !0) : i.find(".slick-track").width() >= i.width() && (a = !0);
                    a ? n.removeClass("hide") : n.addClass("hide")
                },
                initVideo: function(n) {
                    var a = n.data("video-type"),
                        s = n.attr("id");
                    "mp4" === a && "muted" === o[s].style && this.playMp4Video(s), "youtube" === a && t && "muted" === o[s].style && this.requestToPlayYoutubeVideo(s), "vimeo" === a && (i ? this.playOrShowVimeo(s) : e("body").on("vimeoReady" + this.settings.namespace, function() {
                        this.playOrShowVimeo(s)
                    }.bind(this))), this.inModal && this.resizeSlides()
                },
                stopVideo: function(e, t) {
                    e || (this.stopYoutubeVideo(), this.stopVimeoVideo(), this.stopMp4Video()), "youtube" === t && this.stopYoutubeVideo(e), "mp4" === t && this.stopMp4Video(e), "vimeo" === t && this.stopVimeoVideo(e)
                },
                playOrShowVimeo: function(t) {
                    o[t] && "muted" === o[t].style ? this.autoplayVimeoVideo(t) : o[t] && "unmuted" === o[t].style && p(e("#" + t))
                },
                getVideoType: function(e) {
                    return e.data("video-type")
                },
                getVideoId: function(e) {
                    return e.attr("id")
                },
                beforeSlideChange: function(n, s, r, c) {
                    var d = s.$slider,
                        l = d.find(a.currentSlide).find(".product__video"),
                        h = d.find('.slick-slide[data-slick-index="' + c + '"]').find(".product__video");
                    if (r !== c && l.length) {
                        var u = this.getVideoType(l),
                            m = this.getVideoId(l);
                        m && this.stopVideo(m, u)
                    }
                    if (h.length) {
                        var f = this.getVideoType(h),
                            g = this.getVideoId(h);
                        g && "vimeo" === f && (i ? o[g] && "muted" === o[g].style && this.autoplayVimeoVideo(g) : e("body").on("vimeoReady" + this.settings.namespace, function() {
                            o[g] && "muted" === o[g].style && this.autoplayVimeoVideo(g)
                        }.bind(this))), g && "youtube" === f && (t ? o[g] && "muted" === o[g].style && this.requestToPlayYoutubeVideo(g, !0) : e("body").on("youTubeReady" + this.settings.namespace, function() {
                            o[g] && "muted" === o[g].style && this.requestToPlayYoutubeVideo(g, !0)
                        }.bind(this))), g && o[g] && "muted" === o[g].style && "mp4" === f && this.playMp4Video(g), g && o[g] && "muted" != o[g].style && p(e("#" + g))
                    }
                },
                resizeSlides: function() {
                    this.settings.hasMultipleImages && (e(window).trigger("resize.slick"), setTimeout(function() {
                        this.$mainSlider && this.settings.slickMainInitialized && this.$mainSlider.slick("setPosition"), this.$thumbSlider && this.settings.slickThumbInitialized && this.$thumbSlider.slick("setPosition")
                    }.bind(this), 500))
                },
                _slideIndex: function(e) {
                    return e.data("index")
                },
                initQtySelector: function() {
                    this.$container.find(".js-qty__wrapper").each(function() {
                        new theme.QtySelector(e(this), {
                            namespace: ".product"
                        })
                    })
                },
                initAjaxProductForm: function() {
                    "drawer" === theme.settings.cartType && new theme.AjaxProduct(e(this.selectors.formContainer))
                },
                openModalProduct: function() {
                    if (!this.settings.modalInit) {
                        var t = e(this.selectors.modalFormHolder),
                            i = t.data("url");
                        i += "?view=ajax", t.load(i + " #AddToCartForm-" + this.sectionId, function() {
                            this.formSetup(), Shopify.PaymentButton && Shopify.PaymentButton.init()
                        }.bind(this)), this.productSetup(), this.loadModalContent(), this.createImageCarousels(), this.settings.modalInit = !0
                    }
                    this.resizeSlides()
                },
                closeModalProduct: function() {
                    this.stopVideo(), e("body").off(this.settings.namespace), e(window).off(this.settings.namespace)
                },
                loadModalContent: function() {
                    var t = this.checkIfVideos();
                    t && t.indexOf("mp4") > -1 && this.$modal.find('.product__video[data-video-type="mp4"]').each(function(t, i) {
                        var n = e(i),
                            a = n.data("video-src"),
                            o = document.createElement("source");
                        o.setAttribute("src", a), n.append(o)
                    }.bind(this))
                },
                onUnload: function() {
                    this.$container.off(this.settings.namespace), e("body").off(this.settings.namespace), this.destroyImageCarousels()
                }
            }), m
        }(), theme.Recommendations = function() {
            function t(t) {
                var i = this.$container = e(t),
                    n = this.sectionId = i.attr("data-section-id");
                this.selectors = {
                    recommendations: "#Recommendations-" + n,
                    placeholder: ".product-recommendations-placeholder",
                    sectionClass: " .product-recommendations",
                    productResults: ".grid-product"
                }, this.init()
            }
            return t.prototype = e.extend({}, t.prototype, {
                init: function() {
                    var t = e(this.selectors.recommendations);
                    if (t.length && !1 !== t.data("enable")) {
                        var i = t.find(this.selectors.placeholder),
                            n = t.data("product-id"),
                            a = "/recommendations/products?section_id=product-recommendations&limit=" + t.data("limit") + "&product_id=" + n;
                        i.load(a + this.selectors.sectionClass, function(i) {
                            theme.reinitProductGridItem(t), this.updateVariantInventory(t), 0 === e(i).find(this.selectors.sectionClass).find(this.selectors.productResults).length && t.addClass("hide")
                        }.bind(this))
                    }
                },
                updateVariantInventory: function(t) {
                    window.inventories = window.inventories || {}, t.find(".js-product-inventory-data").each(function() {
                        var t = e(this),
                            i = t.data("section-id");
                        window.inventories[i] = {}, e(".js-variant-inventory-data", t).each(function() {
                            var t = e(this);
                            window.inventories[i][t.data("id")] = {
                                quantity: t.data("quantity"),
                                incoming: t.data("incoming"),
                                next_incoming_date: t.data("date")
                            }
                        })
                    })
                }
            }), t
        }(), theme.Collection = function() {
            var t = !1,
                i = {
                    tags: ".tags",
                    hero: ".collection-hero",
                    parallaxContainer: ".parallax-container"
                },
                n = "tag--active";

            function a(t) {
                this.container = t, this.sectionId = e(t).attr("data-section-id"), this.namespace = ".collection-" + this.sectionId;
                var n = this.$heroContainer = e(t).find(".collection-hero");
                if (n.length) {
                    if (this.checkIfNeedReload(), theme.loadImageSection(n), e(t).data("parallax")) {
                        var a = e(t).find(i.parallaxContainer),
                            o = {
                                namespace: this.namespace
                            };
                        theme.parallaxSections[this.namespace] = new theme.Parallax(a, o)
                    }
                } else theme.settings.overlayHeader && theme.headerNav.disableOverlayHeader();
                e(window).on("popstate", function(e) {
                    e && this.getNewCollectionContent(location.href)
                }.bind(this)), this.init()
            }
            return a.prototype = e.extend({}, a.prototype, {
                init: function() {
                    this.$container = e(this.container), this.sectionId = this.$container.attr("data-section-id"), this.sortBy(), this.sortTags(), this.initTagAjax()
                },
                initTagAjax: function() {
                    this.$container.on("click" + this.namespace, ".tags a", function(a) {
                        var o = e(a.currentTarget);
                        if (!o.hasClass("no-ajax") && (a.preventDefault(), !t)) {
                            t = !0;
                            var s = o.attr("href");
                            e(i.tags).find("." + n).removeClass(n), o.parent().addClass(n), history.pushState({}, "", s), e(".grid-product").addClass("unload"), this.getNewCollectionContent(s)
                        }
                    }.bind(this))
                },
                getNewCollectionContent: function(i) {
                    i += "?view=ajax", e("#CollectionAjaxResult").load(i + " #CollectionAjaxContent", function() {
                        t = !1, this.reInit()
                    }.bind(this))
                },
                sortBy: function() {
                    var t = e("#SortBy");
                    t.length && t.on("change", function() {
                        location.href = "?sort_by=" + e(this).val()
                    })
                },
                sortTags: function() {
                    var t = e("#SortTags");
                    t.length && t.on("change", function() {
                        location.href = e(this).val()
                    })
                },
                reInit: function() {
                    for (var e = 0; e < sections.instances.length; e++) {
                        var t = sections.instances[e];
                        "collection-template" === t.type && t.forceReload()
                    }
                    theme.reinitProductGridItem()
                },
                forceReload: function() {
                    this.onUnload(), this.init()
                },
                checkIfNeedReload: function() {
                    Shopify.designMode && theme.settings.overlayHeader && (e(".header-wrapper").hasClass("header-wrapper--overlay") || location.reload())
                },
                onUnload: function() {
                    e(window).off(this.namespace), this.$container.off(this.namespace), theme.parallaxSections[this.namespace] && (theme.parallaxSections[this.namespace].destroy(), delete theme.parallaxSections[this.namespace])
                }
            }), a
        }(), theme.CollectionSwitcher = function() {
            var t = ".collection-switcher__trigger",
                i = ".collection-switcher__collection",
                n = ".collection-switcher__collection-grid";

            function a(t) {
                this.$container = e(t), this.sectionId = this.$container.attr("data-section-id"), this.config = {
                    perRow: this.$container.attr("data-per-row"),
                    perRowMobile: 2
                }, this.sliderArgs = {
                    arrows: !0,
                    infinite: !1,
                    slidesToShow: this.config.perRow,
                    slidesToScroll: this.config.perRow
                }, this.$activeSlider = null, this.init()
            }
            return a.prototype = e.extend({}, a.prototype, {
                init: function() {
                    this.$triggers = this.$container.find(t), this.$collections = this.$container.find(i);
                    var a = this.$container.find(n).first();
                    a.length && (this.initSlider(a), e("body").on("matchSmall unmatchSmall", function() {
                        this.initSlider()
                    }.bind(this))), this.$triggers.on("click", this.switch.bind(this))
                },
                initSlider: function(e) {
                    this.$activeSlider && this.$activeSlider.off("afterChange").slick("unslick"), e || (e = this.$activeSlider), e.length && (theme.config.bpSmall ? (this.sliderArgs.slidesToShow = this.config.perRowMobile, this.sliderArgs.slidesToScroll = this.config.perRowMobile) : (this.sliderArgs.slidesToShow = parseInt(this.config.perRow), this.sliderArgs.slidesToScroll = parseInt(this.config.perRow)), this.$activeSlider = e.slick(this.sliderArgs), AOS && AOS.refresh())
                },
                switch: function(t) {
                    t.preventDefault(), this.$triggers.removeClass("is-active");
                    var i = e(t.currentTarget).addClass("is-active").attr("aria-controls"),
                        a = e("#" + i),
                        o = a.find(n);
                    this.$collections.addClass("hide"), a.removeClass("hide"), this.initSlider(o)
                },
                onUnload: function() {}
            }), a
        }(), theme.HeaderSection = function() {
            var t = "#NavDrawer",
                i = ".mobile-nav__toggle-btn",
                n = ".mobile-nav__has-sublist",
                a = "mobile-nav--expanded";

            function o(t) {
                for (var i = this.$container = e(t), n = (this.sectionId = i.attr("data-section-id"), 0); n < sections.instances.length; n++) {
                    var a = sections.instances[n];
                    "slideshow-section" === a.type && a.forceReload()
                }
                theme.currencySwitcher.init(), this.initDrawers(), theme.headerNav.init(), theme.announcementBar.init()
            }
            return o.prototype = e.extend({}, o.prototype, {
                initDrawers: function() {
                    theme.NavDrawer = new theme.Drawers("NavDrawer", "nav"), "drawer" === theme.settings.cartType && new theme.CartDrawer, this.drawerMenuButtons()
                },
                drawerMenuButtons: function() {
                    e(t).find(".js-drawer-close").on("click", function(e) {
                        e.preventDefault(), theme.NavDrawer.close()
                    });
                    var o = e(i);
                    o.attr("aria-expanded", "false"), o.each(function(t, i) {
                        var n = e(i);
                        n.attr("aria-controls", n.attr("data-aria-controls"))
                    }), o.on("click", function() {
                        var t = e(this),
                            i = !1;
                        "true" === t.attr("aria-expanded") ? t.attr("aria-expanded", "false") : (t.attr("aria-expanded", "true"), i = !0), t.closest(n).toggleClass(a, i)
                    })
                },
                onUnload: function() {
                    theme.NavDrawer.close(), theme.headerNav.unload(), theme.announcementBar.unload()
                }
            }), o
        }(), theme.FeaturedContentSection = function() {
            return function() {
                e(".rte").find("a:not(:has(img))").addClass("text-link")
            }
        }(), theme.slideshows = {}, theme.SlideshowSection = function() {
            var t = {
                parallaxContainer: ".parallax-container"
            };

            function i(i) {
                var n = this.$container = e(i),
                    a = (n.parent(), n.attr("data-section-id"));
                this.slideshow = "#Slideshow-" + a;
                this.namespace = "." + a;
                var o = e(i).find(".hero");
                if (o.length && theme.loadImageSection(o), this.init(), n.data("parallax")) {
                    var s = {
                        namespace: this.namespace
                    };
                    theme.parallaxSections[this.namespace] = new theme.Parallax(n.find(t.parallaxContainer), s)
                }
            }
            return i.prototype = e.extend({}, i.prototype, {
                init: function() {
                    var t = {
                        arrows: e(this.slideshow).data("arrows"),
                        dots: e(this.slideshow).data("dots"),
                        fade: !!this.$container.data("parallax"),
                        speed: 500
                    };
                    theme.slideshows[this.slideshow] = new theme.Slideshow(this.slideshow, t)
                },
                forceReload: function() {
                    this.onUnload(), this.init()
                },
                onUnload: function() {
                    theme.parallaxSections[this.namespace] && (theme.parallaxSections[this.namespace].destroy(), delete theme.parallaxSections[this.namespace]), theme.slideshows[this.slideshow] && (theme.slideshows[this.slideshow].destroy(), delete theme.slideshows[this.slideshow])
                },
                onSelect: function() {
                    e(this.slideshow).slick("slickPause")
                },
                onDeselect: function() {
                    e(this.slideshow).slick("slickPlay")
                },
                onBlockSelect: function(t) {
                    var i = e(this.slideshow),
                        n = e(".slideshow__slide--" + t.detail.blockId + ":not(.slick-cloned)").data("slick-index");
                    i.slick("slickGoTo", n).slick("slickPause")
                },
                onBlockDeselect: function() {
                    e(this.slideshow).slick("slickPlay")
                }
            }), i
        }(), theme.VideoSection = function() {
            var t, i = [],
                n = [],
                a = {
                    width: 1280,
                    height: 720,
                    playerVars: {
                        autohide: 0,
                        branding: 0,
                        cc_load_policy: 0,
                        controls: 0,
                        fs: 0,
                        iv_load_policy: 3,
                        modestbranding: 1,
                        playsinline: 1,
                        quality: "hd720",
                        rel: 0,
                        showinfo: 0,
                        wmode: "opaque"
                    },
                    events: {
                        onReady: function(t) {
                            var i = e(t.target.a),
                                a = i.attr("id");
                            n[a] = t.target;
                            n[a];
                            h(i), n[a].mute(), i.attr("tabindex", "-1"), l(a), e(window).on("scroll." + a, {
                                id: a
                            }, e.throttle(150, l))
                        },
                        onStateChange: function(t) {
                            var a = e(t.target.a),
                                o = a.attr("id"),
                                s = n[o];
                            switch (t.data) {
                                case -1:
                                    i[o].attemptedToPlay && (u(a), p(a));
                                    break;
                                case 0:
                                    s.playVideo();
                                    break;
                                case 1:
                                    u(a);
                                    break;
                                case 3:
                                    i[o].attemptedToPlay = !0
                            }
                        }
                    }
                },
                o = !1,
                s = {
                    byline: !1,
                    title: !1,
                    portrait: !1,
                    loop: !0
                },
                r = {
                    videoParent: ".video-parent-section"
                },
                c = {
                    loading: "loading",
                    loaded: "loaded",
                    interactable: "video-interactable"
                };

            function d(t) {
                var i = this.$container = e(t),
                    n = (this.sectionId = i.attr("data-section-id"), this.youtubePlayerId = "YouTubeVideo-" + this.sectionId);
                this.namespace = "." + n;
                var a = this.vimeoPlayerId = "Vimeo-" + this.sectionId,
                    o = (this.$vimeoTrigger = e("#VimeoTrigger-" + this.sectionId), "Mp4Video-" + this.sectionId),
                    s = e("#" + n),
                    r = e("#" + a),
                    c = e("#" + o);
                this.vimeoPlayer = [], s.length && (this.youtubeVideoId = s.data("video-id"), this.initYoutubeVideo()), r.length && (this.vimeoVideoId = r.data("video-id"), this.initVimeoVideo()), c.length && (u(c), function(e) {
                    return document.querySelector("#" + e).play()
                }(o).then(function() {}).catch(function(e) {
                    p(c)
                }))
            }

            function l(t) {
                var i;
                i = "string" == typeof t ? t : t.data.id, theme.isElementVisible(e("#" + i)) ? function(e) {
                    n[e] && "function" == typeof n[e].playVideo && n[e].playVideo()
                }(i) : function(e) {
                    n[e] && "function" == typeof n[e].pauseVideo && n[e].pauseVideo()
                }(i)
            }

            function h(e) {
                e.closest(r.videoParent).addClass(c.loading)
            }

            function u(e) {
                e.closest(r.videoParent).removeClass(c.loading).addClass(c.loaded)
            }

            function p(e) {
                e.closest(r.videoParent).addClass(c.interactable)
            }
            return d.prototype = e.extend({}, d.prototype, {
                initYoutubeVideo: function() {
                    i[this.youtubePlayerId] = {
                        id: this.youtubePlayerId,
                        videoId: this.youtubeVideoId,
                        type: "youtube",
                        attemptedToPlay: !1
                    }, t ? this.loadYoutubeVideo() : (window.loadYouTube(), e("body").on("youTubeReady" + this.namespace, this.loadYoutubeVideo.bind(this)))
                },
                loadYoutubeVideo: function() {
                    var o = e.extend({}, a, i[this.youtubePlayerId]);
                    o.playerVars.controls = 0, n[this.youtubePlayerId] = new YT.Player(this.youtubePlayerId, o), t = !0
                },
                initVimeoVideo: function() {
                    i[this.vimeoPlayerId] = {
                        divId: this.vimeoPlayerId,
                        id: this.vimeoVideoId,
                        type: "vimeo"
                    }, h(e("#" + this.vimeoPlayerId)), this.$vimeoTrigger.on("click", +this.namespace, function(e) {
                        this.requestToPlayVimeoVideo(this.vimeoPlayerId)
                    }.bind(this)), o ? this.loadVimeoVideo() : (window.loadVimeo(), e("body").on("vimeoReady" + this.namespace, this.loadVimeoVideo.bind(this)))
                },
                loadVimeoVideo: function() {
                    var t = e.extend({}, s, i[this.vimeoPlayerId]);
                    (this.vimeoPlayer[this.vimeoPlayerId] = new Vimeo.Player(i[this.vimeoPlayerId].divId, t), o = !0, theme.config.bpSmall) ? u(e("#" + this.vimeoPlayerId)): this.requestToPlayVimeoVideo(this.vimeoPlayerId)
                },
                requestToPlayVimeoVideo: function(t) {
                    o ? this.playVimeoVideo(t) : e("body").on("vimeoReady" + this.namespace, function() {
                        this.playVimeoVideo(t)
                    }.bind(this))
                },
                playVimeoVideo: function(t) {
                    this.vimeoPlayer[t].play(), this.vimeoPlayer[t].setVolume(0), u(e("#" + t))
                },
                onUnload: function(t) {
                    var i = t.target.id.replace("shopify-section-", "");
                    n["YouTubeVideo-" + i].destroy(), e(window).off("scroll" + this.namespace), e("body").off("vimeoReady" + this.namespace)
                }
            }), d
        }(), theme.BackgroundImage = function() {
            var t = {
                parallaxContainer: ".parallax-container"
            };

            function i(i) {
                var n = e(i),
                    a = n.attr("data-section-id");
                if (this.namespace = "." + a, n.length && n.data("parallax")) {
                    var o = n.find(t.parallaxContainer),
                        s = {
                            namespace: this.namespace,
                            desktopOnly: !0
                        };
                    theme.parallaxSections[this.namespace] = new theme.Parallax(o, s)
                }
            }
            return i.prototype = e.extend({}, i.prototype, {
                onUnload: function(e) {
                    theme.parallaxSections[this.namespace].destroy(), delete theme.parallaxSections[this.namespace]
                }
            }), i
        }(), theme.Testimonials = function() {
            var t = 0,
                i = {
                    accessibility: !0,
                    arrows: !1,
                    dots: !0,
                    autoplay: !1,
                    touchThreshold: 20,
                    slidesToShow: 3,
                    slidesToScroll: 3
                };

            function n(n) {
                var a = (this.$container = e(n)).attr("data-section-id"),
                    o = (this.wrapper = ".testimonials-wrapper", this.slider = "#Testimonials-" + a),
                    s = e(o);
                this.sliderActive = !1;
                var r = e.extend({}, i, {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: !0
                });
                (t = s.data("count")) < i.slidesToShow && (i.slidesToShow = t, i.slidesToScroll = t), s.on("init", this.a11y.bind(this)), theme.config.bpSmall ? this.init(s, r) : this.init(s, i), e("body").on("matchSmall", function() {
                    this.init(s, r)
                }.bind(this)), e("body").on("unmatchSmall", function() {
                    this.init(s, i)
                }.bind(this))
            }
            return n.prototype = e.extend({}, n.prototype, {
                onUnload: function() {
                    e(this.slider, this.wrapper).slick("unslick")
                },
                onBlockSelect: function(t) {
                    var i = e(".testimonials-slide--" + t.detail.blockId + ":not(.slick-cloned)").data("slick-index");
                    e(this.slider, this.wrapper).slick("slickGoTo", i)
                },
                init: function(e, t) {
                    this.sliderActive && (e.slick("unslick"), this.sliderActive = !1), e.slick(t), this.sliderActive = !0, AOS && AOS.refresh()
                },
                a11y: function(t, i) {
                    var n = i.$list,
                        a = e(this.wrapper, this.$container);
                    n.removeAttr("aria-live"), a.on("focusin", function(e) {
                        a.has(e.target).length && n.attr("aria-live", "polite")
                    }), a.on("focusout", function(e) {
                        a.has(e.target).length && n.removeAttr("aria-live")
                    })
                }
            }), n
        }(), theme.Instagram = function() {
            var t = !1;

            function i(t) {
                var i = (this.$container = e(t)).attr("data-section-id");
                this.namespace = ".instagram-" + i, this.$target = e("#Instafeed-" + i), this.$target.length && (this.checkVisibility(), e(window).on("scroll" + this.namespace, e.throttle(100, this.checkVisibility.bind(this))))
            }
            return i.prototype = e.extend({}, i.prototype, {
                checkVisibility: function() {
                    t ? e(window).off("scroll" + this.namespace) : theme.isElementVisible(this.$container) && this.init()
                },
                init: function() {
                    t = !0;
                    var e = this.$target.data("user-id"),
                        i = this.$target.data("client-id"),
                        n = parseInt(this.$target.data("count"));
                    (this.feed = new InstafeedTheme({
                        target: this.$target[0],
                        accessToken: i,
                        get: "user",
                        userId: e,
                        limit: n + 2,
                        template: '<div class="grid__item small--one-half medium-up--one-fifth"><div class="image-wrap"><a href="" target="_blank" style="background-image: url(); display: block; padding-bottom: 100%; background-size: cover; background-position: center;"></a></div></div>',
                        resolution: "standard_resolution"
                    })).run(), this.$target.addClass("instagram-holder--active")
                }
            }), i
        }(), theme.NewsletterPopup = function() {
            function t(t) {
                var i = this.$container = e(t),
                    n = i.attr("data-section-id");
                this.cookieName = "newsletter-" + n, i.length && "/challenge" !== window.location.pathname && (this.data = {
                    secondsBeforeShow: i.data("delay-seconds"),
                    daysBeforeReappear: i.data("delay-days"),
                    cookie: Cookies.get(this.cookieName),
                    testMode: i.data("test-mode")
                }, this.modal = new theme.Modals("NewsletterPopup-" + n, "newsletter-popup-modal"), (i.find(".errors").length || i.find(".note--success").length) && this.modal.open(), i.find(".note--success").length ? this.closePopup(!0) : (e("body").on("modalClose." + i.attr("id"), this.closePopup.bind(this)), this.data.cookie && !this.data.testMode || this.initPopupDelay()))
            }
            return t.prototype = e.extend({}, t.prototype, {
                initPopupDelay: function() {
                    setTimeout(function() {
                        this.modal.open()
                    }.bind(this), 1e3 * this.data.secondsBeforeShow)
                },
                closePopup: function(e) {
                    if (this.data.testMode) Cookies.remove(this.cookieName, {
                        path: "/"
                    });
                    else {
                        var t = e ? 200 : this.data.daysBeforeReappear;
                        Cookies.set(this.cookieName, "opened", {
                            path: "/",
                            expires: t
                        })
                    }
                },
                onLoad: function() {
                    this.modal.open()
                },
                onSelect: function() {
                    this.modal.open()
                },
                onDeselect: function() {
                    this.modal.close()
                },
                onUnload: function() {}
            }), t
        }(), theme.FadingImages = function() {
            var t = "active-image",
                i = "finished-image",
                n = "active-titles",
                a = "finished-titles",
                o = "compensation";

            function s(t) {
                var i = this.$container = e(t),
                    n = i.attr("data-section-id");
                this.namespace = ".fading-images-" + n;
                if (i.length) {
                    var a = i.find(".fading-images");
                    theme.loadImageSection(a), this.data = {
                        isInit: !1,
                        interval: i.data("interval"),
                        block_count: i.data("count"),
                        finish_interval: 1e3,
                        timer_offset: 400,
                        active_image: 1,
                        active_title: 1,
                        removed_compensation: !1
                    }, this.selectors = {
                        $allTitles: i.find(".fading-images-overlay__titles")
                    }, this.checkVisibility(), e(window).on("scroll" + this.namespace, e.throttle(100, this.checkVisibility.bind(this)))
                }
            }
            return s.prototype = e.extend({}, s.prototype, {
                checkVisibility: function() {
                    this.data.isInit ? e(window).off("scroll" + this.namespace) : theme.isElementVisible(this.$container) && (this.startImages(), this.startTitles(), this.data.isInit = !0)
                },
                nextImage: function() {
                    var e = this.$container;
                    this.data.removed_compensation || (e.find(".fading-images__item[data-slide-index=" + this.data.active_image + "]").removeClass(o), this.data.removed_compensation = !0), e.find(".fading-images__item[data-slide-index=" + this.data.active_image + "]").removeClass(t).addClass(i);
                    var n = this.data.active_image;
                    window.setTimeout(function() {
                        e.find(".fading-images__item[data-slide-index=" + n + "]").removeClass(i)
                    }, this.data.finish_interval), this.data.active_image++, this.data.active_image > this.data.block_count && (this.data.active_image = 1), e.find(".fading-images__item[data-slide-index=" + this.data.active_image + "]").addClass(t)
                },
                nextTitle: function() {
                    var e = this.$container,
                        t = this.selectors.$allTitles;
                    this.selectors.$allTitles.removeClass(n).addClass(a), this.data.active_title++, this.data.active_title > this.data.block_count && (this.data.active_title = 1);
                    var i = this.data.active_title;
                    window.setTimeout(function() {
                        var o = e.find(".fading-images__item[data-slide-index=" + i + "]").attr("data-slide-title1"),
                            s = e.find(".fading-images__item[data-slide-index=" + i + "]").attr("data-slide-title2");
                        e.find(".fading-images-overlay__title--1").text(o), e.find(".fading-images-overlay__title--2").text(s), t.removeClass(a).addClass(n)
                    }, this.data.finish_interval - 200)
                },
                startImages: function() {
                    this.$container.find(".fading-images__item[data-slide-index=" + this.data.active_image + "]").addClass(t).addClass(o);
                    var e = this;
                    window.setTimeout(function() {
                        window.setInterval(e.nextImage.bind(e), e.data.interval)
                    }, this.data.timer_offset)
                },
                startTitles: function() {
                    var e = this.$container,
                        t = this.selectors.$allTitles,
                        i = this.data.active_title;
                    window.setTimeout(function() {
                        var a = e.find(".fading-images__item[data-slide-index=" + i + "]").attr("data-slide-title1"),
                            o = e.find(".fading-images__item[data-slide-index=" + i + "]").attr("data-slide-title2");
                        e.find(".fading-images-overlay__title--1").text(a), e.find(".fading-images-overlay__title--2").text(o), t.addClass(n)
                    }, 750);
                    window.setInterval(this.nextTitle.bind(this), this.data.interval)
                },
                onLoad: function() {},
                onSelect: function() {},
                onDeselect: function() {},
                onUnload: function() {}
            }), s
        }(), theme.Maps = function() {
            var t = 14,
                i = null,
                n = [],
                a = {
                    addressNoResults: theme.strings.addressNoResults,
                    addressQueryLimit: theme.strings.addressQueryLimit,
                    addressError: theme.strings.addressError,
                    authError: theme.strings.authError
                },
                o = {
                    section: '[data-section-type="map"]',
                    map: "[data-map]",
                    mapOverlay: "[data-map-overlay]"
                },
                s = "map-section--load-error",
                r = "map-section__error errors text-center";

            function c(t) {
                this.$container = e(t), this.sectionId = this.$container.attr("data-section-id"), this.namespace = ".map-" + this.sectionId, this.$map = this.$container.find(o.map), this.key = this.$map.data("api-key"), this.key && (this.checkVisibility(), e(window).on("scroll" + this.namespace, e.throttle(50, this.checkVisibility.bind(this))))
            }
            return window.gm_authFailure = function() {
                Shopify.designMode && (e(o.section).addClass(s), e(o.map).remove(), e(o.mapOverlay).after('<div class="' + r + '">' + theme.strings.authError + "</div>"))
            }, c.prototype = e.extend({}, c.prototype, {
                prepMapApi: function() {
                    "loaded" === i ? this.createMap() : (n.push(this), "loading" !== i && (i = "loading", void 0 !== window.google && void 0 !== window.google.maps || e.getScript("https://maps.googleapis.com/maps/api/js?key=" + this.key).then(function() {
                        i = "loaded", e.each(n, function(e, t) {
                            t.createMap()
                        })
                    })))
                },
                createMap: function() {
                    var i = this.$map;
                    return function(t) {
                        var i = e.Deferred(),
                            n = new google.maps.Geocoder,
                            a = t.data("address-setting");
                        return n.geocode({
                            address: a
                        }, function(e, t) {
                            t !== google.maps.GeocoderStatus.OK && i.reject(t), i.resolve(e)
                        }), i
                    }(i).then(function(n) {
                        var a = {
                                zoom: t,
                                backgroundColor: "none",
                                center: n[0].geometry.location,
                                draggable: !1,
                                clickableIcons: !1,
                                scrollwheel: !1,
                                disableDoubleClickZoom: !0,
                                disableDefaultUI: !0
                            },
                            o = this.map = new google.maps.Map(i[0], a),
                            s = this.center = o.getCenter();
                        new google.maps.Marker({
                            map: o,
                            position: o.getCenter()
                        });
                        google.maps.event.addDomListener(window, "resize", e.debounce(250, function() {
                            google.maps.event.trigger(o, "resize"), o.setCenter(s), i.removeAttr("style")
                        }))
                    }.bind(this)).fail(function() {
                        var e;
                        switch (status) {
                            case "ZERO_RESULTS":
                                e = a.addressNoResults;
                                break;
                            case "OVER_QUERY_LIMIT":
                                e = a.addressQueryLimit;
                                break;
                            case "REQUEST_DENIED":
                                e = a.authError;
                                break;
                            default:
                                e = a.addressError
                        }
                        Shopify.designMode && i.parent().addClass(s).html('<div class="' + r + '">' + e + "</div>")
                    })
                },
                checkVisibility: function() {
                    theme.isElementVisible(this.$container, 600) && (this.prepMapApi(), e(window).off(this.namespace))
                },
                onUnload: function() {
                    0 !== this.$map.length && google.maps.event.clearListeners(this.map, "resize")
                }
            }), c
        }(), theme.Blog = function() {
            function t(e) {
                this.tagFilters()
            }
            return t.prototype = e.extend({}, t.prototype, {
                tagFilters: function() {
                    var t = e("#BlogTagFilter");
                    t.length && t.on("change", function() {
                        location.href = e(this).val()
                    })
                },
                onUnload: function() {}
            }), t
        }(), theme.Photoswipe = function() {
            var t = ".js-photoswipe__zoom",
                i = ".photoswipe__image",
                n = ".slick-active .photoswipe__image";

            function a(t, i) {
                this.$container = e(t), this.sectionId = i, this.namespace = ".photoswipe-" + this.sectionId, this.gallery, this.$images, this.inSlideshow = !1, "false" !== this.$container.attr("data-zoom") && ("true" === this.$container.attr("data-has-slideshow") && (this.inSlideshow = !0), this.init())
            }
            return a.prototype = e.extend({}, a.prototype, {
                init: function() {
                    var a = this.$container.find(t);
                    this.$images = this.$container.find(i);
                    var o = [];
                    a.on("click" + this.namespace, function(t) {
                        if (o = this.getImageData(), this.inSlideshow) var i = this.$container.find(n).data("index");
                        else i = e(t.currentTarget).data("index");
                        this.initGallery(o, i)
                    }.bind(this))
                },
                getImageData: function() {
                    var t = [];
                    return this.$images.each(function() {
                        var i = {
                            msrc: e(this).prop("currentSrc") || e(this).prop("src"),
                            src: e(this).data("photoswipe-src"),
                            w: e(this).data("photoswipe-width"),
                            h: e(this).data("photoswipe-height"),
                            el: e(this)[0],
                            initialZoomLevel: .5
                        };
                        t.push(i)
                    }), t
                },
                initGallery: function(e, t) {
                    var i = document.querySelectorAll(".pswp")[0],
                        n = {
                            allowPanToNext: !1,
                            captionEl: !1,
                            closeOnScroll: !1,
                            counterEl: !1,
                            history: !1,
                            index: t - 1,
                            pinchToClose: !1,
                            preloaderEl: !1,
                            scaleMode: "zoom",
                            shareEl: !1,
                            tapToToggleControls: !1,
                            getThumbBoundsFn: function(t) {
                                var i = window.pageYOffset || document.documentElement.scrollTop,
                                    n = e[t].el.getBoundingClientRect();
                                return {
                                    x: n.left,
                                    y: n.top + i,
                                    w: n.width
                                }
                            }
                        };
                    this.gallery = new PhotoSwipe(i, PhotoSwipeUI_Default, e, n), this.gallery.init(), this.gallery.listen("afterChange", this.afterChange.bind(this))
                },
                afterChange: function() {
                    if (this.inSlideshow) {
                        var t = e("#ProductPhotos-" + this.sectionId);
                        if (t.hasClass("slick-initialized")) {
                            var i = this.gallery.getCurrentIndex();
                            t.slick("slickGoTo", i)
                        }
                    }
                }
            }), a
        }(), theme.bp = {}, theme.bp.smallUp = 590, theme.bp.small = theme.bp.smallUp - 1, theme.config = {
            bpSmall: !1,
            hasSessionStorage: !0,
            mediaQuerySmall: "screen and (max-width: " + theme.bp.small + "px)",
            mediaQuerySmallUp: "screen and (min-width: " + theme.bp.smallUp + "px)",
            youTubeReady: !1,
            vimeoReady: !1,
            vimeoLoading: !1,
            isTouch: !!("ontouchstart" in window || window.DocumentTouch && window.document instanceof DocumentTouch || window.navigator.maxTouchPoints || window.navigator.msMaxTouchPoints),
            isIpad: /ipad/.test(window.navigator.userAgent.toLowerCase()) || "MacIntel" === window.navigator.platform && window.navigator.maxTouchPoints > 1
        }, theme.config.isIpad && (document.documentElement.className += " js-ipad"), window.onYouTubeIframeAPIReady = function() {
            theme.config.youTubeReady = !0, e("body").trigger("youTubeReady")
        }, window.loadYouTube = function() {
            if (!theme.config.youtubeReady) {
                var e = document.createElement("script");
                e.src = "https://www.youtube.com/iframe_api";
                var t = document.getElementsByTagName("script")[0];
                t.parentNode.insertBefore(e, t)
            }
        }, window.loadVimeo = function() {
            var t, i, n;
            if (!theme.config.vimeoLoading && !theme.config.vimeoReady) {
                theme.config.vimeoLoading = !0;
                var a = document.createElement("script");
                a.src = "https://player.vimeo.com/api/player.js";
                var o = document.getElementsByTagName("script")[0];
                o.parentNode.insertBefore(a, o), (n = e.Deferred(), t = setInterval(function() {
                    Vimeo && (clearInterval(t), clearTimeout(i), n.resolve())
                }, 500), i = setTimeout(function() {
                    clearInterval(t), n.reject()
                }, 4e3), n).then(function() {
                    theme.config.vimeoReady = !0, theme.config.vimeoLoading = !1, e("body").trigger("vimeoReady")
                }).fail(function() {})
            }
        }, theme.init = function() {
            theme.setGlobals(), theme.pageTransitions(), theme.initQuickShop(), theme.settings.predictiveSearch && theme.predictiveSearch.init(), theme.videoModal(), theme.articleImages.init(), theme.customerTemplates.init(), theme.collapsibles.init(), slate.rte.init(), AOS.init({
                easing: "ease-out-quad",
                once: !0,
                offset: 60,
                disableMutationObserver: !0
            }), e(document.documentElement).on("keyup.tab", function(t) {
                9 === t.keyCode && (e(document.documentElement).addClass("tab-outline"), e(document.documentElement).off("keyup.tab"))
            })
        }, theme.setGlobals = function() {
            theme.config.hasSessionStorage = theme.isSessionStorageSupported(), theme.config.isTouch && e("body").addClass("supports-touch"), enquire.register(theme.config.mediaQuerySmall, {
                match: function() {
                    theme.config.bpSmall = !0, e("body").trigger("matchSmall")
                },
                unmatch: function() {
                    theme.config.bpSmall = !1, e("body").trigger("unmatchSmall")
                }
            })
        }, theme.loadImageSection = function(e) {
            function t() {
                e.removeClass("loading loading--delayed").addClass("loaded")
            }

            function i() {
                return e.find(".lazyloaded").length
            }
            if (e.find("svg").length) t();
            else if (i() > 0) t();
            else var n = setInterval(function() {
                i() > 0 && (clearInterval(n), t())
            }, 80)
        }, theme.isSessionStorageSupported = function() {
            if (window.self !== window.top) return !1;
            var e = window.sessionStorage;
            try {
                return e.setItem("test", "1"), e.removeItem("test"), !0
            } catch (e) {
                return !1
            }
        }, theme.isElementVisible = function(e, t) {
            var i = e[0].getBoundingClientRect(),
                n = window.innerHeight || document.documentElement.clientHeight;
            return t = t || 0, i.bottom >= 0 && i.right >= 0 && i.top <= n + t && i.left <= (window.innerWidth || document.documentElement.clientWidth)
        }, theme.pageTransitions = function() {
            1 == e("body").data("transitions") && (navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) && e("a").on("click", function() {
                window.setTimeout(function() {
                    e("body").removeClass("unloading")
                }, 1200)
            }), e('a[href^="mailto:"], a[href^="#"], a[target="_blank"], a[href*="youtube.com/watch"], a[href*="youtu.be/"]').each(function() {
                e(this).addClass("js-no-transition")
            }), e("a:not(.js-no-transition)").bind("click", function(t) {
                if (t.metaKey) return !0;
                t.preventDefault(), e("body").addClass("unloading");
                var i = e(this).attr("href");
                window.setTimeout(function() {
                    location.href = i
                }, 50)
            }), e("a.mobile-nav__link").bind("click", function() {
                theme.NavDrawer.close()
            }))
        }, theme.reinitProductGridItem = function(e) {
            AOS && AOS.refreshHard(), theme.settings.currenciesEnabled && theme.currencySwitcher.ajaxrefresh(), theme.initQuickShop(!0), window.SPR && (SPR.initDomEls(), SPR.loadBadges()), sections.register("product-template", theme.Product, e), theme.collapsibles.init()
        }, window.onpageshow = function(t) {
            t.persisted && e("body").removeClass("unloading")
        }, e(document).ready(function() {
            theme.init(), window.sections = new theme.Sections, sections.register("header-section", theme.HeaderSection), sections.register("slideshow-section", theme.SlideshowSection), sections.register("video-section", theme.VideoSection), sections.register("product", theme.Product), sections.register("product-recommendations", theme.Recommendations), sections.register("product-template", theme.Product), sections.register("collection-template", theme.Collection), sections.register("featured-content-section", theme.FeaturedContentSection), sections.register("collection-switcher", theme.CollectionSwitcher), sections.register("testimonials", theme.Testimonials), sections.register("instagram", theme.Instagram), sections.register("newsletter-popup", theme.NewsletterPopup), sections.register("fading-images", theme.FadingImages), sections.register("background-image", theme.BackgroundImage), sections.register("map", theme.Maps), sections.register("blog", theme.Blog), sections.register("photoswipe", theme.Photoswipe)
        })
    }(theme.jQuery);